import Translations from '../Translations.json';

let fallbackLang = 'en';

function Translate(str, forceLang) {
  let lang = fallbackLang;
  if (forceLang === undefined) {
    if (window.appLang !== undefined) {
      lang = window.appLang;
    }
  } else {
    lang = forceLang;
  }
  if (Translations[lang] === undefined) {
    lang = fallbackLang;
  }
  if (Translations[lang][str] !== undefined) {
    return Translations[lang][str];
  }

  if (lang !== fallbackLang) {
		return Translate(str, fallbackLang);
  }

  return '!!' + str + '!!';
}

export default Translate;
