import React, { Component } from 'react';
import { CirclePicker } from 'react-color';

import __ from '../../Helpers/Translate';

import TextValidator from '../../Elements/Forms/TextValidator';
import { ValidatorForm } from 'react-form-validator-core';

import cachios from 'cachios';


class Edit extends Component {


  constructor(props) {
    super(props);
    this.state = {
      nameSuggestions: []
    };
  }

  componentDidMount() {
    cachios.get('ai/tags_suggestions').then(res => {
      this.setState({ nameSuggestions: res.data })
    });
  }

  setSuggestion(name, e) {
    e.preventDefault();

    this.props.handleNameChange({ target: { value: name } })
  }

  render() {
    console.log(this.props.editingMethod);
    return (<ValidatorForm
      className="form-config"
      ref="form"
      debounceTime={500}
      onSubmit={this.props.saveTag.bind(this)}
    >

      <h2 className="form-config__title">Edit a tag</h2>
      <div className="form-config__container-flex">
        <TextValidator
          onChange={this.props.handleNameChange.bind(this)}
          type="text"
          name="name"
          placeholder="Name"
          value={this.props.editingName}

          labelCopy="Name"
          classNameGlobal={["form-config__el", "form-config__label", "form-config__input"]}

          validators={['required']}
          errorMessages={[__('error.message.thisfieldisrequired')]}
        />

        {(!this.props.editingName.length && this.state.nameSuggestions.length) ? <div className="input-suggestions">

          {this.state.nameSuggestions.map((item) => {

            return (

              <button onClick={this.setSuggestion.bind(this, item)}>{item}</button>

            )

          })}

        </div> : ''}


        <div className="form-config__el">
          <CirclePicker circleSize={24} color={this.props.editingColor} onChangeComplete={this.props.handleColorChange.bind(this)} />
        </div>

        {/* <div className="form-config__el">

          <label className="form-config__label">
            Tagging method:
          </label>

          <label className="form-default__radio" for="methodManual">
            <input onClick={this.props.handleMethodChange.bind(this)} checked={(this.props.editingMethod === 'manual') ? true : false} type="radio" value="manual" id="methodManual" name="tagging_method" />
            <span>Manual</span>
          </label>

          <label className="form-default__radio" for="methodAuto">
            <input onClick={this.props.handleMethodChange.bind(this)} checked={(this.props.editingMethod === 'auto') ? true : false} type="radio" value="auto" id="methodAuto" name="tagging_method" />
            <span>Auto</span>
          </label>
        </div> */}

      </div>
      <div className="form-config__container-button">
        <button className="form-config__button" type="submit">Save</button>
        {
          (this.props.editingItem !== '')
            ? <button className="form-config__button form-config__button--delete" type="button" onClick={this.props.deleteItem.bind(this)}>Delete</button>
            : ''
        }
        <button className="form-config__button form-config__button--cancel" onClick={this.props.closeForm.bind(this)}>Cancel</button>
      </div>
    </ValidatorForm>);
  }
}

export default Edit;
