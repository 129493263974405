import React, {Component} from 'react';

import axios from 'axios';
import __ from '../../Helpers/Translate';
import SetPageTitle from '../../Helpers/PageHelpers';
import Select from 'react-select';

import MessagesHelpers from '../../Helpers/MessagesHelpers';

class Identification extends Component {

  constructor(props) {
    super(props);
    this.state = {
      settings: [],
      subexplanation: '',
      selectedOption: {},
      selectOptions: []
    };
  }

  componentDidMount() {
    SetPageTitle('Identification');

    axios.get(`settings`).then(res => {
      this.setState({settings: res.data});
      this.convertSettings();
    }).catch(error => {});
  }

  saveSettings() {
    axios.post(`settings/identification`, {select: this.state.selectedOption.value}).then(res => {
      MessagesHelpers.pushMessage('success', __('configuration.identification.save.messages.success'));
    }).catch(error => {
      MessagesHelpers.pushMessage('error', __('configuration.identification.save.messages.error'));
    });
  }

  handleChange(e) {
    this.setState({selectedOption: e});

    if (this.state.settings.identification_methods !== undefined && this.state.settings.identification_methods.length) {
      this.state.settings.identification_methods.forEach((item) => {

        if (item.id === e.value) {
          this.setState({subexplanation: item.description});
        }

      })
    }

  }

  convertSettings() {
    let options = [];
    if (this.state.settings.identification_methods !== undefined && this.state.settings.identification_methods.length) {
      this.state.settings.identification_methods.forEach((item) => {
        let selectItem = {
          value: item.id,
          label: item.name
        };

        if (item.selected) {
          this.setState({selectedOption: selectItem});
          this.setState({subexplanation: item.description});
        }
        options.push(selectItem);
      })
    }
    this.setState({selectOptions: options});
  }

  render() {
    return (<div className="identification-container">
      <div className="identification-subcontainer identification">
        <div className="identification-item-subcontainer">
          <h2 className="form-identification__title">{__('configuration.identification.pageTitle')}</h2>
          <div className="form-identification__container">
            <p className="form-identification__description">{__('configuration.identification.explanation')}</p>

            <Select value={this.state.selectedOption} onChange={this.handleChange.bind(this)} options={this.state.selectOptions}/>

            <p className="form-identification__description">{this.state.subexplanation}</p>

            <div>
              <button className="form-identification__button" onClick={this.saveSettings.bind(this)}>{__('configuration.identification.save')}</button>
            </div>

          </div>
        </div>
      </div>
    </div>);
  }
}

export default Identification;
