import React, {Component} from 'react';
import List from './Team/List';
import User from './Team/User';
import {Route, Switch} from 'react-router-dom';

class Team extends Component {

    componentDidMount() {}

    render() {
        return (<div>

            <Switch>
                <Route exact={true} path="/user/team" component={List}/>
                <Route exact={true} path="/user/team/:uuid" component={User}/>
            </Switch>
        </div>);
    };

}

export default Team;
