import {
	toast
} from 'react-toastify';

export default {


	pushMessage: function (type, str) {
		if (type === 'success') {
			toast.success(str, {
				position: toast.POSITION.BOTTOM_RIGHT
			});
		} else if (type === 'error') {
			toast.error(str, {
				position: toast.POSITION.BOTTOM_RIGHT
			});
		} else {
			toast(str, {
				position: toast.POSITION.BOTTOM_RIGHT
			});
		}
	}
};