import React, {Component} from 'react';

class Item extends Component {

  render() {
    return (<div className="tags-tag-list__item">
      <span className="tags-tag-list__color" style={{background:this.props.item.color}}></span>
		  <h5 className="tags-tag-list__name">{this.props.item.name}</h5>
    </div>);
  }
}

export default Item;
