import React, {Component} from 'react'
import axios from 'axios'

import __ from '../../Helpers/Translate'

import TextValidator from '../../Elements/Forms/TextValidator'
import SelectValidator from '../../Elements/Forms/SelectValidator'
import {ValidatorForm} from 'react-form-validator-core'
import MessagesHelpers from '../../Helpers/MessagesHelpers'
import Select from 'react-select'

class Rule extends Component {
    constructor(props) {
        super(props)
        this.state = {
            rulesetUuid: this.props.match.params.ruleset,
            ruleUuid: this.props.match.params.rule,
            updateRuleError: false,
            updateRuleErrorMessage: '',
            name: '',
            eventActions: [],
            tags: [],
            selectRangeType: [
                {
                    value: 'day',
                    label: 'Days'
                }, {
                    value: 'week',
                    label: 'Weeks'
                }, {
                    value: 'month',
                    label: 'Months'
                }, {
                    value: 'year',
                    label: 'Years'
                }
            ],
            action: {
                value: '',
                label: ''
            },
            tag: {
                value: '',
                label: ''
            },
            hitTarget: '',
            score: '',

						fromDateNumber: '12',
						fromDateType: {
                value: 'month',
                label: 'Months'
            },
						toDateNumber: 0,
						toDateType: {
                value: 'day',
                label: 'Days'
            },

						fromDateTypeRaw:null,
						toDateTypeRaw:null,

            actionSelectValue: [],
            tagSelectValue: []
        }
    }

    componentDidMount() {
        this.getEventActions()
        this.getTags()
        this.getRule()
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    handleSelectChange(name, event) {
        this.setState({
            [name]: event
        })
    }


    getRule() {
        axios.get(`ruleset/${this.state.rulesetUuid}/rule/${this.state.ruleUuid}`).then(res => {
            this.setState(state => ({name: res.data.name}))
            if (res.data.data.url !== null) {
                this.setState(state => ({url: res.data.data.url}))
            }
            if (res.data.data.action) {
                this.setState(state => ({action: res.data.data.action}))
            }
            if (res.data.data.tag) {
                this.setState(state => ({tag: res.data.data.tag}))
            }
            if (res.data.data.hitTarget) {
                this.setState(state => ({hitTarget: res.data.data.hitTarget}))
            }
            if (res.data.data.score) {
                this.setState(state => ({score: res.data.data.score}))
            }
            if (res.data.data.dateRange !== undefined) {
							 let daterange = res.data.data.dateRange;

							 if(daterange.from!==undefined){
								 if(daterange.from.value!==undefined){
		                 this.setState(state => ({fromDateNumber: daterange.from.value}))
								 }
									 if(daterange.from.type!==undefined){
			                 this.setState(state => ({fromDateTypeRaw: daterange.from.type}))
											             this.setDaterangeFromValue();
									 }
							 }

 							 if(daterange.to!==undefined){
 								 if(daterange.to.value!==undefined){
 		                 this.setState(state => ({toDateNumber: daterange.to.value}))
 								 }
 									 if(daterange.to.type!==undefined){
 			                 this.setState(state => ({toDateTypeRaw: daterange.to.type}))
											             this.setDaterangeToValue();
 									 }
 							 }


            }

            this.setActionValue()
            this.setTagValue()
        }).catch(error => {})
    }

    deleteRule(rulesetUuid, ruleUuid) {
        axios.post(`ruleset/${rulesetUuid}/rule/delete`, {uuid: ruleUuid}).then(res => {
            this.props.history.push(`/scoring/${this.state.rulesetUuid}`)
        }).catch(error => {})
    }

    getEventActions() {
        axios.get(`event/action`).then(res => {
            let finalArray = []
            res.data.map(function(item) {

                if (this.state.action.value === item.id) {
                    this.setState(state => ({
                        action: {
                            label: item.name,
                            value: item.id
                        }
                    }))
                    this.setActionValue()
                }

                finalArray.push({value: item.id, label: item.name})

                return true
            }, this)
            this.setState({eventActions: finalArray})
        }).catch(error => {})
    }

    updateRule(event) {
        event.preventDefault()

        axios.post(`ruleset/${this.state.rulesetUuid}/rule/edit`, {
            uuid: this.state.ruleUuid,
            name: this.state.name,
            data: {
                action: this.state.action,
                tag: this.state.tag,
                hitTarget: parseInt(this.state.hitTarget),
                score: parseInt(this.state.score),
                dateRange: {
									from: {
										value: parseInt(this.state.fromDateNumber),
										type: this.state.fromDateType.value
									},
									to: {
										value: parseInt(this.state.toDateNumber),
										type: this.state.toDateType.value
									}
                }
            }
        }).then(res => {
            this.getRule()
            MessagesHelpers.pushMessage('success', __('configuration.rule.update.messages.success'))
        }).catch(error => {
            MessagesHelpers.pushMessage('error', __('configuration.rule.update.messages.error'))
        })
    }

    updateAction(e) {
        let self = this
        this.setState({action: e.value})

        setTimeout(function() {
            self.setActionValue()
        }, 250)
    }

    updateTag(e) {
        let self = this
        this.setState({tag: e.value})

        setTimeout(function() {
            self.setTagValue()
        }, 250)
    }

    getTags() {
        axios.get(`tag`).then(res => {
            let finalArray = []
            res.data.map(function(item) {
                finalArray.push({value: item.uuid, label: item.name})
                return true
            })
            this.setState({tags: finalArray})
            this.setTagValue()

        }).catch(error => {})
    }

    logChange(val) {
        this.setState({value: val.value})
    }

    setActionValue() {
        let self = this
        if (this.state.eventActions === undefined) {
            return ''
        }
        if (this.state.eventActions === null) {
            return ''
        }

        this.state.eventActions.forEach(function(action) {
            if (action.value === self.state.action) {
                self.setState({actionSelectValue: action})
            }
        })
    }
    setTagValue() {
        let self = this
        if (this.state.tags === undefined) {
            return ''
        }
        if (this.state.tags === null) {
            return ''
        }

        this.state.tags.forEach(function(tag) {
            if (tag.value === self.state.tag) {
                self.setState({tagSelectValue: tag})
            }
        })
    }

		setDaterangeToValue(){
        let self = this
        if (this.state.toDateTypeRaw === undefined) {
            return ''
        }
        if (this.state.toDateTypeRaw === null) {
            return ''
        }

        this.state.selectRangeType.forEach(function(type) {
            if (type.value === self.state.toDateTypeRaw) {
                self.setState({toDateType: type})
            }
        })
		}

		setDaterangeFromValue(){
        let self = this
        if (this.state.fromDateTypeRaw === undefined) {
            return ''
        }
        if (this.state.fromDateTypeRaw === null) {
            return ''
        }

        this.state.selectRangeType.forEach(function(type) {
            if (type.value === self.state.fromDateTypeRaw) {
                self.setState({fromDateType: type})
            }
        })
		}

    render() {
        return (<div className="rule-container">

            <ValidatorForm className="form-config" ref="form" debounceTime={500} onSubmit={this.updateRule.bind(this)}>

                <legend className="form-config__title">Action of this rule</legend>
                <div className="form-config__container-flex">

                    <TextValidator onChange={this.handleChange.bind(this)} type="text" name="name" placeholder="Name" value={this.state.name} labelCopy="Name" classNameGlobal={["form-config__el", "form-config__label", "form-config__input"]} validators={['required']} errorMessages={[__('error.message.thisfieldisrequired')]}/>

                    <SelectValidator onChange={this.updateAction.bind(this)} name="action" value={this.state.actionSelectValue} options={this.state.eventActions} labelCopy="Action" classNameGlobal={["form-user__el form-config__el--50", "form-user__label", "select-filters react-select-container"]} validators={['required']} errorMessages={[__('error.message.thisfieldisrequired')]}/>

                    <SelectValidator onChange={this.updateTag.bind(this)} name="tag" value={this.state.tagSelectValue} options={this.state.tags} labelCopy="Tags" classNameGlobal={["form-user__el form-config__el--50", "form-user__label", "select-filters react-select-container"]} validators={['required']} errorMessages={[__('error.message.thisfieldisrequired')]}/>

                    <TextValidator onChange={this.handleChange.bind(this)} type="number" name="hitTarget" value={this.state.hitTarget || 0} labelCopy="Hit Target" classNameGlobal={["form-config__el form-config__el--50", "form-config__label", "form-config__input"]} validators={['required', 'minNumber:1']} errorMessages={[__('error.message.thisfieldisrequired'), 'Please insert a number above 0']}/>

                    <TextValidator onChange={this.handleChange.bind(this)} type="number" name="score" value={this.state.score || 0} labelCopy="Score" classNameGlobal={["form-config__el form-config__el--50", "form-config__label", "form-config__input"]} validators={['required', 'minNumber:1']} errorMessages={[__('error.message.thisfieldisrequired'), __('error.message.pleaseinsertanumberabove0')]}/>
{/*
                    <div className="form-config__el">
                        <label className="form-config__label">Url:</label>
                        <input className="form-config__input" type="text" name="url" placeholder="Url" value={this.state.url || ''} onChange={this.handleChange.bind(this)}/>
                    </div>
							*/}

                    <div className="form-config__el--daterange">

												<div class="form-config__el form-config__el--daterange-from">
														<label className="form-config__label">From</label>

														<div className="daterange-form__group">
																<input className="form-config__input" disabled="disabled" value="Today" />
																<span className="daterange-operator"> - </span>
																<div className="daterange-value__group">
																		<input className="form-config__input" type="number" value={this.state.fromDateNumber || 0} name="fromDateNumber" onChange={this.handleChange.bind(this)}/>
																		<Select onChange={this.handleSelectChange.bind(this, 'fromDateType')} name="fromDateType" value={this.state.fromDateType} options={this.state.selectRangeType} className="select-filters react-select-container" classNamePrefix="react-select" />
																</div>
														</div>
												</div>

												<div className="form-config__el form-config__el--daterange-to">
														<label className="form-config__label">To</label>

														<div class="daterange-form__group">
																<input className="form-config__input" disabled="disabled" value="Today" />
																<span className="daterange-operator"> - </span>
																<div className="daterange-value__group">
																		<input className="form-config__input" type="number" value={this.state.toDateNumber || 0} name="toDateNumber" onChange={this.handleChange.bind(this)}/>
																		<Select onChange={this.handleSelectChange.bind(this, 'toDateType')} name="toDateType" value={this.state.toDateType} options={this.state.selectRangeType} className="select-filters react-select-container" classNamePrefix="react-select" />
																</div>
														</div>
												</div>
                    </div>
                </div>
                <div className="form-config__container-button">
                    <button className="form-config__button" type="submit">Save</button>
                    <button className="form-config__button form-config__button--delete" onClick={() => this.deleteRule(this.state.rulesetUuid, this.state.ruleUuid)}>Delete</button>
                </div>

            </ValidatorForm>

        </div>)
    }
}

export default Rule
