import React, { Component } from 'react';
import axios from 'axios';

import __ from '../Helpers/Translate';

import Edit from './Tags/Edit';
import Item from './Tags/Item';

import SetPageTitle from '../Helpers/PageHelpers';
import PanelsLoader from '../Elements/Loaders/PanelsLoader';
import MessagesHelpers from '../Helpers/MessagesHelpers';
class Tags extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayEditForm: false,
      loadingForm: false,
      tags: [],
      editingItem: '',
      editingName: '',
      editingColor: '',
      editingMethod: 'manual'
    };
  }

  addNew(e) {
    e.preventDefault();
    this.setState({ editingItem: '', editingName: '', editingColor: '', displayEditForm: true });
  }

  closeForm(e) {
    e.preventDefault();
    this.setState({ displayEditForm: false });
  }

  componentDidMount() {
    this.loadData();
    SetPageTitle('Tags');
  }

  loadData() {
    this.setState({ editingItem: '', editingName: '', editingColor: '', displayEditForm: false, loadingForm: true });
    axios.get(`tag`).then(res => {
      this.setState({ tags: res.data, loadingForm: false });
    }).catch(error => { });
  }

  editItem(uuid) {
    let self = this;
    this.setState({ editingItem: '', editingName: '', editingColor: '', displayEditForm: false, loadingForm: true });
    axios.get(`tag/${uuid}`).then(res => {
      setTimeout(function () {
        self.setState({ editingItem: uuid, displayEditForm: true, editingName: res.data.name, editingMethod: res.data.method, editingColor: res.data.color, loadingForm: false });
      }, 500);
    }).catch(error => { });
  }

  deleteItem(e) {
    e.preventDefault();
    this.setState({ editingItem: '', editingName: '', editingColor: '', displayEditForm: false, loadingForm: true });
    axios.post(`tag/delete`, { uuid: this.state.editingItem }).then(res => {
      this.setState({ editingItem: '', displayEditForm: false, loadingForm: false });
      this.loadData();
    }).catch(error => { });
  }

  handleColorChange(c) {
    this.setState({ editingColor: c.hex });
  }

  handleNameChange(e) {
    //  e.preventDefault();
    this.setState({ editingName: e.target.value });
  }

  handleMethodChange(e) {
    this.setState({ editingMethod: e.target.value });
  }

  saveTag(e) {
    e.preventDefault();
    let self = this;

    let uuid = '';
    if (this.state.editingItem !== '') {
      uuid = this.state.editingItem;
    }

    this.setState({ displayEditForm: false, loadingForm: true });

    axios.post(`tag/edit`, {
      name: this.state.editingName,
      color: this.state.editingColor,
      method: this.state.editingMethod,
      uuid: uuid
    }).then(res => {
      self.loadData();
      MessagesHelpers.pushMessage('success', __('configuration.tags.save.messages.success'));
    }).catch(error => {
      self.loadData();
      MessagesHelpers.pushMessage('error', __('configuration.tags.save.messages.error'));
    });
  }

  render() {
    let self = this;
    return (<div className="tags-container tags">

      <ul className="tags-tag-list">

        {
          this.state.tags.map(function (item) {
            return (<li key={item.uuid} onClick={self.editItem.bind(self, item.uuid)}>
              <Item item={item} />
            </li>)
          })
        }

        <li>
          <button className="tags-tag-list__button" button="button" onClick={this.addNew.bind(this)}>{__('configuration.event.new')}</button>
        </li>

      </ul>

      {
        (this.state.displayEditForm)
          ? <Edit handleMethodChange={this.handleMethodChange.bind(this)} handleNameChange={this.handleNameChange.bind(this)} editingMethod={this.state.editingMethod} handleColorChange={this.handleColorChange.bind(this)} deleteItem={this.deleteItem.bind(this)} editingItem={this.state.editingItem} editingName={this.state.editingName} editingColor={this.state.editingColor} saveTag={this.saveTag.bind(this)} closeForm={this.closeForm.bind(this)} />
          : ''
      }

      {
        (this.state.loadingForm)
          ? <PanelsLoader />
          : ''
      }

    </div>);
  }
}

export default Tags;
