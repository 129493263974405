import React from 'react';
import {ValidatorComponent} from 'react-form-validator-core';

class TextValidator extends ValidatorComponent {

    render() {
        const {
            errorMessages,
            validators,
            requiredError,
            validatorListener,
            labelCopy,
            classNameGlobal,
            ...rest
        } = this.props;

        return (<div className={classNameGlobal[0]}>

            <label className={(
                    this.errorText())
                    ? classNameGlobal[1] + ' -error'
                    : classNameGlobal[1]}>{labelCopy}: {this.errorText()}</label>
            <input className={classNameGlobal[2]} {...rest} ref={(r) => {
                    this.input = r;
                }}/>

        </div>);
    }

    errorText() {
        const {isValid} = this.state;

        if (isValid) {
            return null;
        }

        return (<span className="error-message">
            {this.getErrorMessage()}
        </span>);
    }
    // errorText() {
    //     const { value, isValid } = this.state;

    //     if (value.length <= 0) {
    //         return null;
    //     } else {

    //         if (isValid){

    //             return (
    //                 <span className="error-message">
    //                     kedis
    //                 </span>
    //             );

    //         }
    //         return (
    //             <span className="error-message">
    //                 {this.getErrorMessage()}
    //             </span>
    //         );

    //     }

    // }
}

export default TextValidator;
