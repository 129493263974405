import React, { Component } from 'react';
import LeadCity from '../../../Elements/Lead/LeadCity';
import LeadNoImage from '../../../Elements/Lead/LeadNoImage';
import LeadImage from '../../../Elements/Lead/LeadImage';

import Icon from '../../../Elements/Icon';

import Moment from 'react-moment';
import 'moment-timezone';
import __ from '../../../Helpers/Translate';

import CountUp from 'react-countup';

class Element extends Component {
    render() {
        return (<div className="leads-item">

            <div className="leads-item__container leads-item__container--user">

                {
                    (this.props.data.knownLead !== undefined)
                        ? <LeadImage lead={this.props.data.knownLead} />
                        : <LeadNoImage />
                }

                {(this.props.type === "lookalike") ?
                    <span className="lookalike-score">
                        <span className="inner"><CountUp start={0} end={parseInt(this.props.score)} />%</span>
                    </span>

                    : ''
                }

                <div className="leads-item__subcontainer leads-item__subcontainer--infos">
                    <p className={(this.props.data.knownLead !== undefined) ? "leads-item__name -known" : "leads-item__name -unknown"}>
                        {
                            (this.props.data.lastSeen !== undefined) ?
                                (this.props.data.knownLead !== undefined)
                                    ? this.props.data.uuid + ' (known)'
                                    : this.props.data.uuid + ' (unknown)'
                                : ''
                        }
                    </p>

                </div>
            </div>

            <div className="leads-item__container leads-item__container--last-seen">
                <div className="leads-item__subcontainer">
                    {
                        (this.props.data.lastSeen !== undefined && this.props.data.lastSeen.device !== undefined)
                            ? (this.props.data.lastSeen.device.type !== undefined)
                                ? <Icon className="" icon={this.props.data.lastSeen.device.type} />
                                : ''
                            : ''
                    }

                    <span className="leads-item__label">{__('leads.global.lastseen')}&nbsp;</span>
                    <Moment fromNow={true} locale="en">{this.props.data.lastAction}</Moment>
                </div>
                {
                    (this.props.data.lastSeen !== undefined && this.props.data.lastSeen.location.country !== null)
                        ? <span className="leads-item__place">
                            <LeadCity lead={this.props.data} />
                        </span>
                        : ''
                }

            </div>

        </div>);
    }
}

export default Element;
