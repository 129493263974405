import React, {Component} from 'react';
import axios from 'axios';

import __ from '../../../Helpers/Translate';
import {NavLink} from 'react-router-dom';

class List extends Component {

    constructor(props) {
        super(props);
        this.state = {
            plugins: null,
            toggleLoading: false
        };
    }

    loadData() {
        axios.get(`plugin`).then(res => {
            this.setState({plugins: res.data});
        }).catch(error => {});
    }

    componentDidMount() {
        this.loadData();
    }

    togglePlugin(sysName) {
        this.setState({toggleLoading: true});
        axios.post(`plugin/toggle/${sysName}`).then(res => {
            this.loadData();
            this.setState({toggleLoading: false});
        }).catch(error => {});
    }

    render() {
        let self = this;
        return (<div className="plugin-container">
            <ul className="plugin-list">
                {
                    (this.state.plugins !== null)
                        ? this.state.plugins.map(function(plugin) {
                            return (<li className="plugin-el">

                                <img className="plugin-el__img" src={plugin.informations.logo} alt={plugin.informations.name}/>

                                <div className="plugin-el__container">
                                    <h2 className="plugin-el__title">{plugin.informations.name}</h2>
                                    <p className="plugin-el__description">{plugin.informations.description}</p>
                                </div>

                                <div className="plugin-el__container plugin-el__container--interaction">
                                    {
                                        (plugin.enabled)
                                            ? <NavLink className="plugin-el__button" to={"/configuration/plugins/" + plugin.sysName}>{__('configuration.plugins.configure.label')}</NavLink>
                                            : ''
                                    }

                                    <label className="checkbox-plugin label-checkbox">
                                        <input className="label-checkbox__checkbox" disabled={self.state.toggleLoading} type="checkbox" checked={plugin.enabled} onChange={self.togglePlugin.bind(self, plugin.sysName)}/>

                                        <span>
                                            <svg className="svg" x="0px" y="0px" viewBox="0 0 67.2 67.2">
                                                <path className="check" d="M54.3,18.8L30.8,46.5L13,32.6"/>
                                            </svg>
                                        </span>
                                    </label>

                                </div>

                            </li>)
                        })
                        : ''
                }
            </ul>
        </div>);
    }
}

export default List;
