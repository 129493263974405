import React, {Component} from 'react';
import cachios from 'cachios';
import {CopyToClipboard} from 'react-copy-to-clipboard';

import __ from '../../../Helpers/Translate';
class TrackerPixel extends Component {

    constructor(props) {
        super(props);
        this.state = {
            pixel: '',
            copied: false
        }
    }

    componentDidMount() {
        this.getPixel();
    }

    getPixel() {
        cachios.get(`generator`).then(res => {
            this.setState(state => ({pixel: res.data.mail}));
        }).catch(error => {});
    }

    render() {
        return (<div className="trackers-step trackers-step--last-step">

            <div className={(this.state.copied) ? 'trackers-step-circle -active' : 'trackers-step-circle'}>

                <div className="trackers-step-circle__container">

                    <span className="trackers-step-circle__number">2</span>

                    <svg className="svg" x="0px" y="0px" viewBox="0 0 67.2 67.2">
                        <path className="check" d="M54.3,18.8L30.8,46.5L13,32.6"/>
                    </svg>
                </div>

            </div>

            <p className="trackers-step__title">{ __('configuration.trackers.pixel.title') }</p>
            <p className="trackers-step__text">{ __('configuration.trackers.pixel.subtitle') }</p>

            <p className="trackers-pixel">{this.state.pixel.pixel}</p>

            <CopyToClipboard text={this.state.pixel.pixel} onCopy={() => this.setState({copied: true})}>
                <button className="trackers-step__link">{ __('configuration.trackers.general.copyclipboard') }</button>
            </CopyToClipboard>
        </div>);
    }
}

export default TrackerPixel;
