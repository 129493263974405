import React, {Component} from 'react';
import axios from 'axios';

import __ from '../../../Helpers/Translate';

import ConfigurationForm from './Configure/ConfigurationForm';
import NoConfiguration from './Configure/NoConfiguration';

import MessagesHelpers from '../../../Helpers/MessagesHelpers';
class ConfigurePlugin extends Component {

    constructor(props) {
        super(props);
        this.state = {
            plugin: null,
            sysName: this.props.match.params.sysname,
            account: []
        };
    }

    loadData() {
        axios.get(`plugin/${this.state.sysName}`).then(res => {
            this.setState({plugin: res.data});
        }).catch(error => {});
        axios.get(`user`).then(res => {
            this.setState({account: res.data.account});
        }).catch(error => {});
    }

    componentDidMount() {
        this.loadData();
    }

    saveConfiguration(data) {
        axios.post(`plugin/${this.state.sysName}/edit`, data).then(res => {
            this.loadData();
            MessagesHelpers.pushMessage('success', __('configuration.plugin.update.messages.success'));
        }).catch(error => {});
    }

    render() {
        return (<div className="plugin-container">

            {
                (this.state.plugin !== null)
                    ? <div className="plugin-config">
                            <div className="plugin-config-el">

                                <img className="plugin-config-el__img" src={this.state.plugin.informations.logo} alt={this.state.plugin.informations.name}/>

                                <div className="plugin-config-el__container">
                                    <h2 className="plugin-config-el__title">{this.state.plugin.informations.name}</h2>
                                    <p className="plugin-config-el__description">{this.state.plugin.informations.description}</p>
                                </div>

                            </div>
                            {/* <div className=""> */}

                            {
                                (this.state.plugin.configuration !== null)
                                    ? (this.state.plugin.configuration !== undefined)
                                        ? <ConfigurationForm account={this.state.account} onSave={this.saveConfiguration.bind(this)} configuration={this.state.plugin.configuration}/>

                                        : <NoConfiguration/>
                                    : <NoConfiguration/>
                            }

                            {/* </div> */}
                        </div>
                    : ''
            }

        </div>);
    }
}

export default ConfigurePlugin;
