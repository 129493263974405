import React, { Component } from 'react';

import { Route, Switch, NavLink } from 'react-router-dom';

import SetPageTitle from '../Helpers/PageHelpers';
import Action from './Actions/Action';
import axios from 'axios';

class Actions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      actions: [],
      editingAction: null
    };
  }

  componentDidMount() {
    SetPageTitle('Actions');
    this.refresh();
  }

  refresh() {
    axios.get(`action`).then(res => {
      this.setState(state => ({ actions: res.data }))
    }).catch(error => { });
  }

  setEditingUuid(uuid) {
    this.setState({ editingAction: null });
    setTimeout(() => {
      this.setState({ editingAction: uuid });
    }, 100)
  }

  render() {
    return (<div className="actions-container">

      <div className="actions-sidebar">

        {
          this.state.actions.map((action) => {
            console.log(action);
            return (<NavLink onClick={this.setEditingUuid.bind(this, action.uuid)} activeClassName="-active" key={action.uuid} className="actions-item" to={"/actions/" + action.uuid}>{action.name}<span className="description">
              <span className="color-indicator" style={{
                backgroundColor: (action.data !== undefined && action.data.color !== undefined)
                  ? action.data.color
                  : '#333'
              }}></span>{action.desc} </span></NavLink>)
          })
        }

        <NavLink onClick={this.setEditingUuid.bind(this, 'new')} activeClassName="-active" className="actions-item -item-new" to="/actions/new">
          New
          <span>Create a new action</span>
        </NavLink>
      </div>

      <div className="actions-edit">
        <Switch>
          <Route exact={true} path="/actions/:action" render={(props) => (this.state.editingAction !== null) ? <Action refresh={this.refresh.bind(this)} {...props} /> : ''} />
        </Switch>
      </div>

    </div>);
  }

}

export default Actions
