import React, { Component } from 'react';

import { FaUser, FaLock, FaEnvelope } from 'react-icons/fa';

import __ from '../Helpers/Translate';
import Conf from '../Conf';
import Cookies from 'js-cookie';
import axios from 'axios';

import pageTitle from '../Helpers/PageHelpers';
class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            mail: '',
            password: '',
            displayForgotPasswordFrom: false,
            statusMessage: false,
            displayEmailLogin: false
        };
    }

    componentDidMount() {
        pageTitle(__('login.pageTitle'));
    }

    socialLogin() {
        window.gapi.load('auth2', function () {
            let auth2 = window.gapi.auth2.init({ client_id: Conf.googleLogin.clientId });

            auth2.grantOfflineAccess().then(function (resp) {
                let auth_code = resp.code;

                axios.post('auth/social', {
                    provider: 'google',
                    code: auth_code
                }).then(res => {
                    Cookies.set('logged', res.data.success.token);
                    document.location.reload();
                });
            });

        });
    }

    emailLogin() {
        this.setState({
            displayEmailLogin: true
        });
    }

    handleChange(event) {
        this.setState({
            statusMessage: '',
            [event.target.name]: event.target.value
        });
    }

    loginUser(e) {
        e.preventDefault();

        this.props.loginAction(this.state.mail, this.state.password);
        this.setState({ statusMessage: '' });
    }

    forgotPassword(e) {
        e.preventDefault();

        axios.post('login/forgotpassword', { mail: this.state.mail }).then(res => {
            this.setState({ statusMessage: __('login.forgotpassword.success') });
            this.closeForgotPasswordForm();
        });
    }

    displayForgotPasswordForm(e) {
        this.setState({ displayForgotPasswordFrom: true })
    }

    closeForgotPasswordForm(e) {
        this.setState({ displayForgotPasswordFrom: false })
    }

    render() {
        return (<div className="app-login" data-loading={this.props.loading} data-error={this.props.error}>
            {
                (this.state.displayEmailLogin) ?
                    (!this.state.displayForgotPasswordFrom)
                        ? <form className="form-login" onSubmit={this.loginUser.bind(this)}>

                            <h1 className="form-login__title">{__('login.title')}</h1>
                            <div className="form-el">
                                <FaUser />
                                <input className="form-el__input" disabled={this.props.loading} placeholder={__('login.mail')} type="text" onChange={this.handleChange.bind(this)} name="mail" value={this.mail} />
                            </div>
                            <div className="form-el">
                                <FaLock />
                                <input className="form-el__input" disabled={this.props.loading} placeholder={__('login.password')} type="password" onChange={this.handleChange.bind(this)} name="password" value={this.password} />
                            </div>

                            {
                                (this.props.error)
                                    ? <p className="error-message-login">
                                        {__('login.messages.' + this.props.errorMessage)}
                                    </p>

                                    : ''
                            }

                            {
                                (this.state.statusMessage)
                                    ? (<p className="error-message-login">
                                        {this.state.statusMessage}
                                    </p>)
                                    : ''
                            }

                            <div className="form-el form-el--button">
                                <button className="button-login" disabled={this.props.loading} onClick={this.loginUser.bind(this)} type="submit">{__('login.login')}</button>
                                <button className="button-forgot" disabled={this.props.loading} onClick={this.displayForgotPasswordForm.bind(this)} type="button">{__('login.forgotpassword')}</button>
                            </div>


                        </form>
                        : <form className="form-login">

                            <h1 className="form-login__title">{__('login.forgotpassword.title')}</h1>
                            <div className="form-el">
                                <FaEnvelope />
                                <input className="form-el__input" placeholder={__('login.mail')} type="text" onChange={this.handleChange.bind(this)} name="mail" value={this.mail} />
                            </div>
                            <div className="form-el form-el--infos">
                                {
                                    (this.state.statusMessage)
                                        ? (<p className="form-el__infos">{__('login.forgotpassword.success')}</p>)
                                        : ''
                                }
                            </div>

                            <div className="form-el form-el--button">
                                <button className="button-login" onClick={this.forgotPassword.bind(this)} type="submit">Reset password</button>

                                <button className="button-cancel" onClick={this.closeForgotPasswordForm.bind(this)} type="button">Cancel</button>

                            </div>

                        </form> : <div className="form-login">

                        <h1 className="form-login__title">{__('login.title')}</h1>


                        <button className="button-login-type button-login-type__mail" onClick={this.emailLogin.bind(this)} type="button">{__('login.login.email')}</button>

                        <div className="login-separator"><span>{__('login.login.or')}</span></div>

                        <button className="button-login-type button-login-type__social button-login-type__google" onClick={this.socialLogin.bind(this)} type="button">{__('login.login.google')}</button>

                    </div>
            }

        </div>);
    }
}

export default Login;
