import React, {Component} from 'react';
import Breadcrumbs from 'react-router-dynamic-breadcrumbs';
import Conf from '../Conf';

class AppBreadcrumbs extends Component {
  render() {
    return (<div className="breadcrumbs">
      <Breadcrumbs mappedRoutes={Conf.breadcrumbs}/>
    </div>);
  }
}

export default AppBreadcrumbs;
