import React, { Component } from 'react'
import axios from 'axios'
import cachios from 'cachios'

import __ from '../../Helpers/Translate';

import TextValidator from '../../Elements/Forms/TextValidator';

import { ValidatorForm } from 'react-form-validator-core';
import MessagesHelpers from '../../Helpers/MessagesHelpers';


import { CirclePicker } from 'react-color';
import CountUp from 'react-countup';
import PopulationQueryBuilder from '../../Elements/Population/QueryBuilder';
class Details extends Component {
  constructor(props) {
    super(props)
    this.state = {
      uuid: this.props.match.params.segment,
      url: window.location.href,
      name: '',
      data: {
        population: [],
        color: '999'
      },
      populationCount: 0,
      populationCountOld: 0,
      populationCounting: false
    }
  }

  componentDidMount() {
    if (!this.state.url.includes('segments/new')) {
      this.getSegment()
    }
  }

  getSegment() {
    axios.get(`segment/${this.state.uuid}`).then(res => {
      this.setState(state => ({ name: res.data.name }))
      this.setState(state => ({ data: res.data.data }))

      this.estimatePopulation();
    }).catch(error => { })
  }

  createSegment() {
    axios.post(`segment/edit`, {
      name: this.state.name,
      data: this.state.data
    }).then(res => {
      this.props.history.push(`/segments`)
    }).catch(error => { })
  }

  updateSegment(e) {
    axios.post(`segment/edit`, {
      uuid: this.state.uuid,
      name: this.state.name,
      data: this.state.data,
    }).then(res => {
      this.getSegment(); MessagesHelpers.pushMessage('success', __('configuration.segments.save.messages.success'));

    }).catch(error => {
      MessagesHelpers.pushMessage('error', __('configuration.segments.save.messages.error'));
    })
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  submitForm(e) {
    e.preventDefault()

    if (this.state.url.includes('segments/new')) {
      this.createSegment(e)
    } else {
      this.updateSegment(e)
    }
  }

  deleteSegment() {
    axios.post(`segment/delete`, { uuid: this.state.uuid }).then(res => {
      this.props.history.push(`/segments`)
    }).catch(error => { })
  }


  estimatePopulation() {

    if (!this.state.populationCounting) {

      this.setState(state => ({ populationCountOld: this.state.populationCount }));
      this.setState(state => ({ populationCounting: true }));

      this.setState(state => ({ populationCounting: true }));
      setTimeout(() => {
        let popTree = '';
        let popQuery = '';
        if (this.state.data !== undefined) {
          if (this.state.data !== null && this.state.data.population !== undefined) {
            if (this.state.data.population.object !== undefined) {
              popTree = this.state.data.population.object;
            }
            if (this.state.data.population.query !== undefined) {
              popQuery = this.state.data.population.query;
            }
          }
        }

        cachios.post('action/estimate', { population: popTree, stringquery: popQuery }).then(res => {
          this.setState(state => ({ populationCount: res.data.count }));
          this.setState(state => ({ populationCounting: false }));
          this.setState(state => ({ populationCounting: false }));
        }).catch(error => { });
      }, 100);
    }
  }
  updatePopulation(tree) {
    this.updateData('population', tree); this.estimatePopulation();
  }
  handleColorChange(c) {
    this.updateData('color', c.hex);
  }
  updateData(key, value) {
    let dataObject = this.state.data;
    dataObject[key] = value;
    dataObject = { ...dataObject };

    this.setState(state => ({ data: dataObject }));
  }

  render() {

    return (<div className="segments-subcontainer">
      {/* <form onSubmit={this.submitForm.bind(this)}> */}
      <ValidatorForm className="form-segments" ref="form" debounceTime={500} onSubmit={this.submitForm.bind(this)}>
        <h2 className="form-segments__title">Edit/Create segment</h2>
        <div className="form-segments__container">

          <TextValidator onChange={this.handleChange.bind(this)} type="text" name="name" placeholder="Name" value={this.state.name} labelCopy="Name" classNameGlobal={["form-segments__el form-segments__el--50", "form-segments__label", "form-segments__input"]} validators={['required']} errorMessages={[__('error.message.thisfieldisrequired')]} />
          <CirclePicker circleSize={24} color={(this.state.data !== null && this.state.data.color !== undefined) ? this.state.data.color : ''} onChangeComplete={this.handleColorChange.bind(this)} />

          <div className="segment-querybuilder">
            {
              (this.state.data !== null && this.state.data.population !== undefined)
                ? <PopulationQueryBuilder updatePopulation={this.updatePopulation.bind(this)} population={this.state.data.population} />
                : ''
            }


            <div className="querybuilder-statistics">
              <div className={(this.state.populationCounting) ? 'inner-statistics segment-population-stats --loading' : 'inner-statistics segment-population-stats'}>
                <div className="query">
                  {(this.state.data !== null && this.state.data.population !== undefined) ? (this.state.data.population.query !== undefined) ? (typeof this.state.data.population.query === 'string') ? this.state.data.population.query : '' : '' : ''}
                </div>
                <div className="calculation">
                  <CountUp start={this.state.populationCountOld} end={this.state.populationCount} /> matching leads
            </div>
              </div>
            </div>

          </div>

        </div>
        <div className="form-segments__container-button">

          <button className="form-segments__button" type='submit'>Save</button>
          <button className="form-segments__button form-segments__button--delete" type='button' onClick={this.deleteSegment.bind(this)}>Delete</button>

        </div>

        {/* </form> */}
      </ValidatorForm>

    </div>)
  }
}

export default Details
