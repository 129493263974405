import React, {Component} from 'react'
import axios from 'axios'

import __ from '../../Helpers/Translate';

import TextValidator from '../../Elements/Forms/TextValidator';
import PasswordStrength from '../../Elements/Forms/PasswordStrength';
import SelectValidator from '../../Elements/Forms/SelectValidator';
import {ValidatorForm} from 'react-form-validator-core';

import MessagesHelpers from '../../Helpers/MessagesHelpers';
class Account extends Component {

    constructor(props) {
        super(props)
        this.state = {
            url: window.location.href,
            id: '',
            name: '',
            email: '',
            lang: '',
            password: '',
            passwordVerif: '',
            errorPassword: false,
            errorPasswordMessage: 'Passwords must be same',
            succesCreateUser: false,
            options: [
                {
                    value: 'fr',
                    label: 'French'
                }, {
                    value: 'en',
                    label: 'English'
                }
            ]
        }
    }

    componentDidMount() {
        if (!this.state.url.includes('user/team/new')) {
            this.getUser()
        }
    }

    getUser() {
        const component = this

        axios.get(`user`).then(res => {
            console.log(res.data)
            this.setState(state => ({name: res.data.name}))
            this.setState(state => ({email: res.data.email}))
            this.setState(state => ({id: res.data.id}))

            this.state.options.forEach(function(option) {
                if (option.value === res.data.lang) {
                    component.setState(state => ({
                        lang: {
                            value: res.data.lang,
                            label: option.label
                        }
                    }))
                }
            })
        }).catch(error => {})
    }

    updateUser(e) {
        e.preventDefault()

        if (this.state.password || this.state.passwordVerif) {

            if (this.state.password === this.state.passwordVerif) {
                this.setState({errorPassword: false})

                axios.post(`account/user/edit`, {
                    id: this.state.id,
                    name: this.state.name,
                    email: this.state.email,
                    lang: this.state.lang.value,
                    password: this.state.password,
                    passwordVerif: this.state.passwordVerif
                }).then(res => {
                    this.setState({password: ''})
                    this.setState({passwordVerif: ''})

                    MessagesHelpers.pushMessage('success', __('user.account.edit.messages.success'));

                }).catch(error => {
                    MessagesHelpers.pushMessage('error', __('user.account.edit.messages.error'));
                })
            } else {
                this.setState({errorPassword: true})
            }

        } else {
            axios.post(`account/user/edit`, {
                id: this.state.id,
                name: this.state.name,
                email: this.state.email,
                lang: this.state.lang.value
            }).then(res => {
                this.getUser();
                MessagesHelpers.pushMessage('success', __('user.account.edit.messages.success'));
            }).catch(error => {
                MessagesHelpers.pushMessage('error', __('user.account.edit.messages.error'));
            })
        }
    }

    createUser(e) {
        e.preventDefault()

        if (this.state.password !== '' || this.state.passwordVerif !== '' || this.state.name !== '' || this.state.email !== '' || this.state.lang !== '') {

            if (this.state.password === this.state.passwordVerif) {
                axios.post(`account/user/edit`, {
                    name: this.state.name,
                    email: this.state.email,
                    lang: this.state.lang.value,
                    password: this.state.password,
                    passwordVerif: this.state.passwordVerif
                }).then(res => {
                    this.props.history.push(`/user/team`)
                    MessagesHelpers.pushMessage('success', __('user.account.update.messages.success'));
                }).catch(error => {
                    MessagesHelpers.pushMessage('error', __('user.account.update.messages.error'));
                })
            } else {
                this.setState({errorPassword: true})
            }

        }
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleChangeLang(e) {
        this.setState({
            lang: {
                value: e.value,
                label: e.label
            }
        })
    }

    submitFormUser(e) {
        if (this.state.url.includes('user/team/new')) {
            this.createUser(e)
        } else {
            this.updateUser(e)
        }
    }

    render() {
        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            if (value !== this.state.password) {
                return false;
            }
            return true;
        });

        ValidatorForm.addValidationRule('noRequired', (value) => {
            if (value !== this.state.password) {
                return false;
            }
            return true;
        });

        ValidatorForm.addValidationRule('isPasswordMatchnoRequired', (value) => {
            if (value !== this.state.password) {
                return false;
            }
            return true;
        });

        return (<div className="account-container">
            <ValidatorForm className="form-user" ref="form" debounceTime={500} onSubmit={this.submitFormUser.bind(this)}>
                <h2 className="form-user__title">Edit my account</h2>
                <div className="form-user__container-flex">
                    <TextValidator onChange={this.handleChange.bind(this)} type="text" name="name" placeholder="Name" value={this.state.name} labelCopy="Name" classNameGlobal={["form-user__el form-config__el--50", "form-user__label", "form-user__input"]} validators={['required']} errorMessages={[__('error.message.thisfieldisrequired')]}/>

                    <TextValidator onChange={this.handleChange.bind(this)} type="text" name="email" placeholder="Email" value={this.state.email} labelCopy="Email" classNameGlobal={["form-user__el form-config__el--50", "form-user__label", "form-user__input"]} validators={['required', 'isEmail']} errorMessages={[__('error.message.thisfieldisrequired'), 'email is not valid']}/> {
                        (this.state.url.includes('user/team/new'))
                            ? <span className="form-user__container-span-flex"><PasswordStrength onChange={this.handleChange.bind(this)} type="password" name="password" placeholder="New password" value={this.state.password} labelCopy="New password" classNameGlobal={["form-user__el form-config__el--50", "form-user__label", "form-user__input"]} validators={['required', 'isPasswordStrength']} errorMessages={[__('error.message.thisfieldisrequired'), __('error.message.passwordhavetobestrength')]}/>
                                    <TextValidator onChange={this.handleChange.bind(this)} type="password" name="passwordVerif" placeholder="Confirm new password" value={this.state.passwordVerif} labelCopy="Confirm new password" classNameGlobal={["form-user__el form-config__el--50", "form-user__label", "form-user__input"]} validators={['required', 'isPasswordMatch']} errorMessages={[__('error.message.thisfieldisrequired'), __('error.message.pleaseinsertthesamepassword')]}/>

                                </span>
                            : <span className="form-user__container-span-flex">
                                    <PasswordStrength onChange={this.handleChange.bind(this)} type="password" name="password" placeholder="New password" value={this.state.password} labelCopy="New password" classNameGlobal={["form-user__el form-config__el--50", "form-user__label", "form-user__input"]} validators={['noRequired', 'isPasswordStrength']} errorMessages={['', __('error.message.passwordhavetobestrength')]}/>
                                    <TextValidator onChange={this.handleChange.bind(this)} type="password" name="passwordVerif" placeholder="Confirm new password" value={this.state.passwordVerif} labelCopy="Confirm new password" classNameGlobal={["form-user__el form-config__el--50", "form-user__label", "form-user__input"]} validators={['isPasswordMatchnoRequired']} errorMessages={[__('error.message.pleaseinsertthesamepassword')]}/>

                                </span>
                    }

                    <SelectValidator onChange={this.handleChangeLang.bind(this)} name="lang" value={this.state.lang} options={this.state.options} labelCopy="Language" classNameGlobal={["form-user__el", "form-user__label", "select-filters react-select-container"]} validators={['required']} errorMessages={[__('error.message.thisfieldisrequired')]}/>

                </div>
                <div className="form-user__container-button">
                    <button className="form-user__button" type="submit">Save</button>
                </div>

            </ValidatorForm>
        </div>)

    }

}

export default Account
