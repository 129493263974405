import React, { Component } from 'react';
import axios from 'axios';

import __ from '../../Helpers/Translate';

//import { CirclePicker } from 'react-color';
//import { FaFileUpload } from 'react-icons/fa';

import TextValidator from '../../Elements/Forms/TextValidator';
import { ValidatorForm } from 'react-form-validator-core';

import MessagesHelpers from '../../Helpers/MessagesHelpers';
class Organization extends Component {

    constructor(props) {
        super(props)
        this.state = {
            account: [],
            file: '',
            //imagePreviewUrl: '',
            name: "",
            color: "",
            displayCurrentImg: true
        }
    }

    componentDidMount() {
        this.getUser()
    }

    getUser() {
        axios.get(`account`).then(res => {
            this.setState(state => ({ account: res.data }))
        }).catch(error => { })
    }

    updateAccount(e) {
        e.preventDefault()

        let data = new FormData()
        data.append('logo', this.state.file, this.state.file.name)
        data.append('name', this.state.account.name)
        data.append('color', this.state.account.color)
        data.append('uuid', this.state.account.uuid)

        let settings = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }

        axios.post(`account/edit`, data, settings).then(res => {
            this.getUser()
            MessagesHelpers.pushMessage('success', __('user.organization.edit.messages.success'));
        }).catch(error => {
            MessagesHelpers.pushMessage('success', __('user.organization.edit.messages.error'));
        })
    }

    changeAccountAttribute(key, value) {
        let account = this.state.account;
        account[key] = value;
        this.setState({ account: account });
    }

    handleChangeName(event) {
        this.changeAccountAttribute('name', event.target.value);
    }

    // handleImageChange(e) {
    //     e.preventDefault();

    //     let reader = new FileReader();
    //     let file = e.target.files[0];

    //     reader.onloadend = () => {
    //         this.setState({ displayCurrentImg: false, file: file, imagePreviewUrl: reader.result });
    //     }

    //     reader.readAsDataURL(file)
    // }

    handleColorChange(e) {
        this.changeAccountAttribute('color', e.hex);
    }

    // deleteImagePreview() {
    //     this.setState({ imagePreviewUrl: '', file: '' })
    //     this.fileInput.value = "";
    // }

    render() {
        //let { imagePreviewUrl } = this.state;
        // let $imagePreview = null;
        // if (imagePreviewUrl) {
        //     $imagePreview = (<img className="upload-file-img" alt="Logo" src={imagePreviewUrl} />);
        // }
        return (<div className="organization-container">
            {/* <form className="form-user" onSubmit={this.updateAccount.bind(this)}> */}
            <ValidatorForm className="form-user" ref="form" debounceTime={500} onSubmit={this.updateAccount.bind(this)}>
                <h2 className="form-user__title">Edit my organization</h2>
                <div className="form-user__container-flex">

                    <TextValidator onChange={this.handleChangeName.bind(this)} type="text" name="name" placeholder="Name" value={this.state.account.name} labelCopy="Name" classNameGlobal={["form-user__el", "form-user__label", "form-user__input"]} validators={['required']} errorMessages={[__('error.message.thisfieldisrequired')]} />

                    {/* <div className="form-user__el-file">
                        <p className="form-user__label">Logo:</p>

                        <label className="upload-file-button">
                            <FaFileUpload className="icon"/>
                            Upload file
                            <input className="upload-file-button__input" accept='image/*' type="file" onChange={(e) => this.handleImageChange(e)} ref={ref => this.fileInput = ref}/>
                        </label>

                        {$imagePreview}

                        {
                            (this.state.displayCurrentImg)
                                ? <img className="upload-file-img" alt="Logo" src={this.state.account.logo}/>
                                : ''
                        }

                        {
                            (!this.state.displayCurrentImg)
                                ? <button className="form-user__delete-file" type="button" onClick={this.deleteImagePreview.bind(this)}>Delete</button>
                                : ''
                        }

                    </div>

                    <div className="form-user__el">
                        <p className="form-user__label">Color:</p>
                        <CirclePicker color={this.state.account.color} onChangeComplete={this.handleColorChange.bind(this)}/>
                    </div> */}

                </div>

                <div className="form-user__container-button">
                    <button className="form-user__button" type="submit">Save</button>
                </div>

            </ValidatorForm>
        </div>)
    }

}

export default Organization;
