import React, { Component } from 'react';
import Popup from './Messages/Popup';

class Messages extends Component {

  constructor(props) {
    super(props);
    this.state = {
      messages: []
    }
  }

  componentDidMount() {
    setInterval(() => {
      if (window.ttMessages !== undefined) {
        this.setState({ messages: window.ttMessages });
      }
    }, 3000);
  }

  removeMessage() {

  }

  render() {
    return (<div className={(
      this.props.navAsideClose)
      ? "app-messages -collapse"
      : "app-messages"}>
      {
        (this.state.messages.map(item => {
          return (<Popup removeMessage={this.removeMessage.bind(this)} styleName={item.type}>
            {item.message}
          </Popup>)
        }))
      }
    </div>);
  }
}

export default Messages;
