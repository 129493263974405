import React, {Component} from 'react';

class SubNav extends Component {

  constructor(props) {
    super(props);
    this.state = {
      subnavActiveBlockWidth: 0,
      subnavActivePosLeft: 0
    };
  }

  subnavActiveBar(e) {
    let currentElementWidth = e.target.offsetWidth;
    let currentElementPosLeft = e.target.offsetLeft;

    this.setState({subnavActiveBlockWidth: currentElementWidth, subnavActivePosLeft: currentElementPosLeft});
  }

  render() {
    return (<div className="subnav-container">
      <ul className="subnav-list" onClick={this.subnavActiveBar.bind(this)}>
        {this.props.children}

        <li className="subnav-list__el-target" style={{
            width: this.state.subnavActiveBlockWidth,
            left: this.state.subnavActivePosLeft
          }}></li>

      </ul>
    </div>);
  }
}

export default SubNav;
