import React, {Component} from 'react';
import Default from './Configuration/Default';
import Domains from './Configuration/Domains';
import Identification from './Configuration/Identification';
import Trackers from './Configuration/Trackers';
import Plugins from './Configuration/Plugins';
import {Route, Switch} from 'react-router-dom';
import {CheckPermissions} from '../Helpers/Permission';

import __ from '../Helpers/Translate';

import pageTitle from '../Helpers/PageHelpers';
class Configuration extends Component {

    componentDidMount() {
        pageTitle(__('configuration.pageTitle'));
    }

    render() {
        return (<div className="configuration-container configuration">
            <Switch>
                {CheckPermissions('configure_domains') && <Route exact={true} path="/configuration/domains" component={Domains}/>}
                <Route exact={false} path="/configuration/identification" component={Identification}/>
                <Route exact={false} path="/configuration/trackers" component={Trackers}/>
                <Route exact={false} path="/configuration/plugins" component={Plugins}/>
                <Route exact={true} path="/configuration" component={Default}/>
            </Switch>

        </div>);
    }
}

export default Configuration;
