import React, {Component} from 'react';

import {FaSpinner} from 'react-icons/fa';

class InfiniteTimelineLoader extends Component {
  render() {
    return (
			<div className="infinite-scroll-loader timeline-infinite-scroll-loader">

				<span className="vertical-timeline-element-icon" style={{background: "rgb(160,160,160)", color: "rgb(255, 255, 255)"}}><FaSpinner/></span>

			</div>
);
  }
}

export default InfiniteTimelineLoader;
