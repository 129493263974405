import React, {Component} from 'react';
import ReactCountryFlag from "react-country-flag";

import countries from 'i18n-iso-countries';

class LeadCity extends Component {
  render() {
    countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
    return (<span>
      {
        (this.props.lead.lastSeen.location !== undefined)
          ? (this.props.lead.lastSeen.location.country !== null)
            ? <ReactCountryFlag code={this.props.lead.lastSeen.location.country.toLowerCase()} svg={true}/>
            : ''
          : ''
      }
      {
        (this.props.lead.lastSeen.location.city)
          ? this.props.lead.lastSeen.location.city
          : countries.getName(this.props.lead.lastSeen.location.country, 'en')
      }
    </span>);
  }
}

export default LeadCity;
