import React, {Component} from 'react';

import __ from '../Helpers/Translate';
class TooManyRequests extends Component {
    render() {
        return (<div className="api-blocked api-blocked-too-many-requests" style={{zIndex:99999999, display:'block', position:'fixed', top:0,left:0,right:0,bottom:0,background:'rgba(255,255,255,.9)', color:"black", fontSize:"24px", textAlign:"center", padding:"25px"}}>
            {__('error.toomanyrequests.message')}
        </div>);
    }
}

export default TooManyRequests;
