import React, {Component} from 'react';
import axios from 'axios';
import {NavLink} from 'react-router-dom';

import __ from '../../Helpers/Translate';

import TextValidator from '../../Elements/Forms/TextValidator';
import {ValidatorForm} from 'react-form-validator-core';
import MessagesHelpers from '../../Helpers/MessagesHelpers';
class List extends Component {

    constructor(props) {
        super(props);
        this.state = {
            scoring: [],
            createRulesetError: false,
            createRulesetErrorMessage: '',
            cloneRulesetError: false,
            cloneRulesetErrorMessage: '',
            name: '',
            description: '',
            displayFormCreate: false,
            inputSearch: ''
        };
    }

    componentDidMount() {
        this.getScoring();
    }

    stateRuleset(uuid) {
        axios.post(`ruleset/activate`, {uuid: uuid}).then(res => {
            this.getScoring();
        }).catch(error => {});
    }

    getScoring() {
        axios.get(`ruleset`).then(res => {
            this.setState(state => ({scoring: res.data}))
        }).catch(error => {});
    }

    createRuleset(e) {
        e.preventDefault();

        if (this.state.name !== '') {
            axios.post(`ruleset/edit`, {
                name: this.state.name,
                description: this.state.description
            }).then(res => {
                this.getScoring();
                this.setState({createRulesetError: false, createRulesetErrorMessage: '', displayFormCreate: false, name: '', description: ''});
                MessagesHelpers.pushMessage('success', __('configuration.scoring.create.messages.success'));
            }).catch(error => {
                this.setState({createRulesetError: true, createRulesetErrorMessage: 'Error'});
                MessagesHelpers.pushMessage('error', __('configuration.scoring.create.messages.error'));
            });
        } else {
            this.setState({createRulesetError: true, createRulesetErrorMessage: 'Name is required'});
        }
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    onChangeHandlerSearch(e) {
        this.setState({inputSearch: e.target.value})
    }

    displayFormCreate(e) {
        e.preventDefault();
        this.setState({displayFormCreate: true});
    }
    hideFormCreate(e) {
        e.preventDefault();
        this.setState({displayFormCreate: false});
    }
    cloneRuleset(e) {
        axios.get(`ruleset/${e.target.id}/clone`).then(res => {
            this.getScoring();
            this.setState({cloneRulesetError: false, cloneRulesetErrorMessage: '', displayFormCreate: false, name: '', description: ''});
            MessagesHelpers.pushMessage('success', __('configuration.scoring.clone.messages.success'));
        }).catch(error => {
            this.setState({cloneRulesetError: true, cloneRulesetErrorMessage: 'Error'});
            MessagesHelpers.pushMessage('error', __('configuration.scoring.clone.messages.error'));
        });
    }

    render() {
        const scoringSearch = this.state.scoring.filter(ruleset => ruleset.name.toLowerCase().includes(this.state.inputSearch.toLowerCase()) || this.state.inputSearch === '')

        return (<div className="scoring-container">
            <div className="scoring-container__title">
                <input placeholder="Search" className='input-filters' value={this.state.inputSearch} type="text" onChange={this.onChangeHandlerSearch.bind(this)}/>
            </div>
            <ul className="scoring-list">
                {
                    scoringSearch.map(ruleset => <li key={ruleset.uuid} className="scoring-el">

                        <button className={(
                                ruleset.active)
                                ? "scoring-button  scoring-button--checked"
                                : "scoring-button scoring-button--unchecked"} onClick={() => this.stateRuleset(ruleset.uuid)}>
                            <span className="scoring-button__check">
                                <svg className="svg" x="0px" y="0px" viewBox="0 0 67.2 67.2">
                                    <path className="check" d="M54.3,18.8L30.8,46.5L13,32.6"/>
                                </svg>
                            </span>
                        </button>

                        <div className="scoring-el__container">
                            <h2 className="scoring-el__title">{ruleset.name}</h2>
                            <p className="scoring-el__description">{ruleset.description}</p>
                        </div>

                        <button className="scoring-el__button" id={ruleset.uuid} onClick={this.cloneRuleset.bind(this)}>
                            Clone
                        </button>

                        <NavLink className="scoring-el__button" key={ruleset.uuid} to={`/scoring/${ruleset.uuid}`}>
                            Edit
                        </NavLink>

                    </li>)
                }
            </ul>

            {
                (this.state.displayFormCreate)
                    ? <ValidatorForm className="form-config" ref="form" debounceTime={500} onSubmit={this.createRuleset.bind(this)}>

                            <legend className="form-config__title">Create new ruleset</legend>
                            <div className="form-config__container-flex">

                                <TextValidator onChange={this.handleChange.bind(this)} type="text" name="name" placeholder="Name" value={this.state.name} labelCopy="Name" classNameGlobal={["form-config__el", "form-config__label", "form-config__input"]} validators={['required']} errorMessages={[__('error.message.thisfieldisrequired')]}/>

                                <div className="form-config__el">
                                    <label className="form-config__label">Description:</label>
                                    <input className="form-config__input" type="text" name="description" placeholder="Description" onChange={this.handleChange.bind(this)}/>
                                </div>

                            </div>
                            <div className="form-config__container-button">
                                <button className="form-config__button" type="submit">Save</button>

                                <button className="form-config__button form-config__button--cancel" onClick={this.hideFormCreate.bind(this)}>Cancel</button>

                                {/* {
                                (this.state.createRulesetError)
                                    ? <ErrorMessage>{this.state.createRulesetErrorMessage}</ErrorMessage>
                                    : ''
                            } */
                                }
                            </div>

                        </ValidatorForm>
                    : <button className="scoring-container__button" onClick={this.displayFormCreate.bind(this)}>New ruleset</button>
            }

        </div>);
    }

}

export default List
