import React, {Component} from 'react';
import {Route, Switch} from 'react-router-dom';

import List from './Scoring/List';
import Ruleset from './Scoring/Ruleset';
import Rule from './Rule/Rule';

import SetPageTitle from '../Helpers/PageHelpers';
class Scoring extends Component {
    componentDidMount() {
        SetPageTitle('Scoring');
    }
    render() {
        return (<Switch>
            <Route exact={true} path="/scoring" component={List}/>
            <Route exact={true} path="/scoring/:ruleset" component={Ruleset}/>
            <Route exact={true} path="/scoring/:ruleset/:rule" component={Rule}/>
        </Switch>);
    }

}

export default Scoring
