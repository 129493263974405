import React, { Component } from 'react';

import { NavLink } from 'react-router-dom';

import axios from 'axios';
import cachios from 'cachios';
import __ from '../../Helpers/Translate';
import MessagesHelpers from '../../Helpers/MessagesHelpers';

import TextValidator from '../../Elements/Forms/TextValidator';

import { ValidatorForm } from 'react-form-validator-core';
import PopulationQueryBuilder from '../../Elements/Population/QueryBuilder';

import CountUp from 'react-countup';

import Select from 'react-select';

import { CirclePicker } from 'react-color';

class Action extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uuid: this.props.match.params.action,
      name: 'New action',
      data: {
        'population': [],
        'trigger': null,
        'action': null,
        'color': '#000'
      },
      populationCount: 0,
      populationCountOld: 0,
      populationCounting: false,
      availableActions: []
    };
  }

  componentDidMount() {
    this.loadAction();

    cachios.get('plugin/actions/list').then(res => {
      this.setState(state => ({ availableActions: res.data }));
    }).catch(error => { });
  }

  loadAction() {
    if (this.state.uuid !== 'new') {
      axios.get('action/' + this.state.uuid).then(res => {
        this.setState(state => ({ name: res.data.name }));
        this.setState(state => ({ data: res.data.data }));
        this.estimatePopulation();
        setTimeout(() => {
          this.estimatePopulation();
        }, 1250);
      }).catch(error => { });
    }
  }

  estimatePopulation() {

    if (!this.state.populationCounting) {

      this.setState(state => ({ populationCountOld: this.state.populationCount }));
      this.setState(state => ({ populationCounting: true }));

      this.setState(state => ({ populationCounting: true }));
      setTimeout(() => {
        let popTree = '';
        let popQuery = '';
        if (this.state.data !== undefined) {
          if (this.state.data.population !== undefined) {
            if (this.state.data.population.object !== undefined) {
              popTree = this.state.data.population.object;
            }
            if (this.state.data.population.query !== undefined) {
              popQuery = this.state.data.population.query;
            }
          }
        }

        axios.post('action/estimate', {
          population: popTree,
          stringquery: popQuery
        }).then(res => {
          this.setState(state => ({ populationCount: res.data.count }));
          this.setState(state => ({ populationCounting: false }));
          this.setState(state => ({ populationCounting: false }));
        }).catch(error => { });
      }, 100);
    }
  }

  updatePopulation(tree) {
    this.updateData('population', tree);
    this.estimatePopulation();
    setTimeout(() => {
      this.estimatePopulation();
    }, 1250);
  }

  updateData(key, value) {
    let dataObject = this.state.data;
    dataObject[key] = value;
    dataObject = {
      ...dataObject
    };

    this.setState(state => ({ data: dataObject }));
  }

  updateDataFromField(event) {
    this.updateData(event.target.name, event.target.value);
  }

  save() {
    let uuid = this.state.uuid;
    if (uuid === 'new') {
      uuid = null;
    }

    let dataObject = this.state.data;

    axios.post(`action/edit`, {
      uuid: uuid,
      name: this.state.name,
      data: dataObject
    }).then(res => {
      this.props.refresh();
      MessagesHelpers.pushMessage('success', __('actions.update.messages.success'));
    }).catch(error => {
      MessagesHelpers.pushMessage('error', __('actions.update.messages.error'));
    });
  }

  delete() {
    axios.post(`action/delete`, { uuid: this.state.uuid }).then(res => {
      this.props.refresh();
      MessagesHelpers.pushMessage('success', __('actions.delete.messages.success'));
    }).catch(error => {
      MessagesHelpers.pushMessage('error', __('actions.delete.messages.error'));
    });
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  changePopulation(query) {
    this.setState(state => ({
      data: {
        population: query
      }
    }))
  }

  updateAction(e) {
    //if (this.state.data !== undefined && this.state.data.actions !== undefined) {
    this.updateData('action', e.value);
    //}
  }

  updateActionParameter(name, e) {
    let parameters = [];

    if (this.state.data !== undefined && this.state.data.actionParameters !== undefined && this.state.data.actionParameters !== null) {
      parameters = this.state.data.actionParameters;
    }

    parameters[name] = e.target.value;

    parameters = {
      ...parameters
    };

    this.updateData('actionParameters', parameters);
  }

  getActionParameterValue(name) {

    if (this.state.data !== undefined && this.state.data.actionParameters !== undefined && this.state.data.actionParameters !== null) {
      let parameters = this.state.data.actionParameters;

      if (parameters[name] !== undefined && parameters[name] !== null)
        return parameters[name];
    }

    return '';
  }

  handleColorChange(e) {
    this.updateData('color', e.hex);
  }

  getActionValue(id, actions) {
    let output = null;

    actions.forEach((action) => {
      if (action.options !== undefined) {
        action.options.forEach((option) => {
          if (option.value === id) {
            output = option;
          }
        });
      }
    });

    return output;
  }

  render() {

    let availableActions = [];
    let selectedActionOption = null;

    if (this.state.availableActions !== undefined && this.state.availableActions.length) {
      availableActions = this.state.availableActions.map((item) => {
        let pluginOptions = item.actions;

        item.actions.forEach((optionItem) => {
          if (this.state.data !== null)
            if (this.state.data.action !== null)
              if (this.state.data.action !== undefined)
                if (optionItem.value === this.state.data.action)
                  selectedActionOption = optionItem;
        }
        );

        return { label: item.name, options: pluginOptions }
      });
    }

    let parameters = null;
    this.state.availableActions.forEach((source) => {

      source.actions.forEach((action) => {

        if (action.value === this.state.data.action) {

          if (action.parameters !== undefined) {

            parameters = action.parameters;

          }

        }

      });

    });


    return (<ValidatorForm className="form-action" ref="form" debounceTime={500} onSubmit={this.save.bind(this)}>
      <div className="action-panel">
        <legend className="form-config__title">Action informations</legend>

        <div className="action-inner-edit">
          <TextValidator onChange={this.handleChange.bind(this)} type="text" name="name" placeholder="Name" value={this.state.name} labelCopy="Name" classNameGlobal={["form-config__el", "form-config__label", "form-config__input"]} validators={['required']} errorMessages={[__('error.message.thisfieldisrequired')]} />
        </div>
        <div className="action-inner-edit">
          <CirclePicker circleSize={24} color={this.state.data.color} onChangeComplete={this.handleColorChange.bind(this)} />  </div>
      </div>

      <div className="action-panel">
        <legend className="form-config__title">Population</legend>
        <div className="action-inner-edit">
          <div className="querybuilder">
            <PopulationQueryBuilder updatePopulation={this.updatePopulation.bind(this)} population={this.state.data.population} />
          </div>
        </div>
        <div className="querybuilder-statistics">
          <div className={(
            this.state.populationCounting)
            ? 'inner-statistics action-population-footer --loading'
            : 'inner-statistics action-population-footer'}>
            <div className="query">
              {
                (this.state.data.population !== undefined)
                  ? (this.state.data.population.query !== undefined)
                    ? (typeof this.state.data.population.query === 'string')
                      ? this.state.data.population.query
                      : ''
                    : ''
                  : ''
              }
            </div>
            <div className="calculation">
              <CountUp start={this.state.populationCountOld} end={this.state.populationCount} />&nbsp;matching leads
            </div>
          </div>
        </div>
      </div>

      <div className="action-panel">
        <legend className="form-config__title">Actions</legend>

        <div className="action-inner-edit">

          <div className="actions-list">
            <div>

              <Select value={selectedActionOption} options={availableActions} onChange={this.updateAction.bind(this)} /> {

                (parameters !== null)
                  ? <div className="action-parameters">

                    <p class="action-parameters-label">
                      Parameters
                      </p>

                    {
                      (parameters.map((parameter) => {

                        return (parameter.type === 'text')
                          ? (<input className="form-config__input" name={parameter.name} value={this.getActionParameterValue(parameter.name)} onChange={this.updateActionParameter.bind(this, parameter.name)} placeholder={parameter.label} required={parameter.required} />)

                          : (parameter.type === 'textarea')
                            ? (<textarea className="form-config__input" name={parameter.name} value={this.getActionParameterValue(parameter.name)} onChange={this.updateActionParameter.bind(this, parameter.name)} placeholder={parameter.label} required={parameter.required} />)

                            : ''

                      }))
                    }

                  </div>

                  : ''

              }

            </div>

          </div>

        </div>
      </div>

      <div className="action-panel">
        <legend className="form-config__title">Trigger</legend>

        <div className="action-inner-edit">
          <label className="form-default__radio" htmlFor="triggerOneTime"><input checked={(
            this.state.data.trigger !== undefined && this.state.data.trigger === 'one_time')
            ? true
            : false} onChange={this.updateDataFromField.bind(this)} type="radio" name="trigger" value="one_time" disabled={(selectedActionOption !== null && selectedActionOption.triggers.one_time === true) ? false : true} id="triggerOneTime" />
            <span>Triggers one time per matching lead</span>
          </label>
          <label className="form-default__radio" htmlFor="triggerEachTime"><input checked={(
            this.state.data.trigger !== undefined && this.state.data.trigger === 'each_time')
            ? true
            : false} onChange={this.updateDataFromField.bind(this)} type="radio" name="trigger" value="each_time" disabled={(selectedActionOption !== null && selectedActionOption.triggers.each_time === true) ? false : true} id="triggerEachTime" />
            <span>Triggers each time</span>
          </label>
        </div>
      </div>

      <div className="form-config__container-button">
        <NavLink to="/actions" className="form-config__button" onClick={this.save.bind(this)}>
          Save</NavLink>
        <NavLink to="/actions" className="form-config__button form-config__button--delete" onClick={this.delete.bind(this)}>
          Delete</NavLink>
      </div>

    </ValidatorForm>);
  }

}

export default Action
