import React, {Component} from 'react';

class Item extends Component {

  render() {
    return (
      <button className="domains-list__link item">
        <span className="domains-list__link-tag">{this.props.item.name}</span>
        {this.props.item.url}
      </button>);
  }
}

export default Item;
