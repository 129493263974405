import React, {Component} from 'react';
import __ from '../../../../Helpers/Translate';

import {FaExclamationCircle} from 'react-icons/fa';

class NoConfiguration extends Component {

  render() {
    return (<div className="form-plugin__el -no-config">
      <FaExclamationCircle/>
			{ __('configuration.plugins.noconf') }

    </div>);
  }
}

export default NoConfiguration;
