import React, {Component} from 'react'
import axios from 'axios'

import __ from '../../../Helpers/Translate';
import MessagesHelpers from '../../../Helpers/MessagesHelpers'

class List extends Component {

    constructor(props) {
        super(props)
        this.state = {
            uuid: this.props.match.params.uuid,
            user: '',
            permissions: [],
            newPermissions: [],
            confirmDeleteDisplay: false
        }
    }

    componentDidMount() {
        this.getUser()
        this.getPermissions()
    }

    getUser() {
        axios.get(`account/user/${this.state.uuid}`).then(res => {
            this.setState(state => ({user: res.data}))
        }).catch(error => {})
    }

    getPermissions() {
        axios.get(`account/user/${this.state.uuid}/permission`).then(res => {
            this.setState(state => ({permissions: res.data}))
        }).catch(error => {})
    }

    updateUser(e) {
        e.preventDefault()

        axios.post(`account/user/${this.state.uuid}/permission/edit`, {permissions: this.state.permissions}).then(res => {
            MessagesHelpers.pushMessage('success', __('user.account.edit.permission.messages.success'));
        }).catch(error => {
            MessagesHelpers.pushMessage('error', __('user.account.edit.permission.messages.error'));
        })
    }

    handleChangePermission(e) {
        const component = this

        this.state.permissions.forEach((permission, index) => {
            if (e.target.name === 'access_segments' && component.state.permissions[index].id === 'access_leads' && e.target.checked === false) {
                component.state.permissions[index].status = false
                e.target.checked = false
                console.log('toto')
            }

            if (permission.id === e.target.name) {
                component.state.permissions[index].status = e.target.checked
            }
        })
    }

    deleteUser() {
        this.setState({confirmDeleteDisplay: true})
    }

    cancelConfirm() {
        this.setState({confirmDeleteDisplay: false})
    }

    confirmDelete() {
        axios.post(`account/user/delete`, {uuid: this.state.user.uuid}).then(res => {
            this.props.history.push(`/user/team`)
        }).catch(error => {})
    }

    render() {
        return (<div className="team-container">

            <form className="form-user" onSubmit={this.updateUser.bind(this)}>
                <h2 className="form-user__title">{this.state.user.name}
                    {/* <p>{this.state.user.email}</p> */}
                </h2>

                <div className="form-user__container-flex">
                    <div className="form-user__el">
                        <p className="form-user__label">Permissions</p>
                        <div className="form-user__input">
                            {
                                this.state.permissions.map(permission => <label className="checkbox-team-user label-checkbox">

                                    <input className="label-checkbox__checkbox" type='checkbox' name={permission.id} checked={permission.status} defaultChecked={permission.status} onChange={this.handleChangePermission.bind(this)}/>

                                    <span>
                                        <svg className="svg" x="0px" y="0px" viewBox="0 0 67.2 67.2">
                                            <path className="check" d="M54.3,18.8L30.8,46.5L13,32.6"/>
                                        </svg>
                                    </span>

                                    {permission.id}

                                </label>)
                            }
                        </div>
                    </div>
                </div>

                {
                    (this.state.confirmDeleteDisplay)
                        ? <div className="form-user__container-button">
                                <button className="form-user__button" type='button' onClick={this.confirmDelete.bind(this)}>Confirm</button>
                                <button className="form-user__button form-user__button--delete" type='button' onClick={this.cancelConfirm.bind(this)}>Cancel</button>
                            </div>
                        : <div className="form-user__container-button">
                                <button className="form-user__button" type='submit'>Save modification</button>
                                <button className="form-user__button form-user__button--delete" onClick={this.deleteUser.bind(this)} type='button'>Delete this user</button>
                            </div>
                }

            </form>

        </div>)
    }

}

export default List
