import React, { Component } from 'react';
import cachios from 'cachios';
import ReactChartkick, { LineChart } from 'react-chartkick'
import Chart from 'chart.js'

import __ from '../../../Helpers/Translate';
ReactChartkick.addAdapter(Chart)

class DailyEventsWidget extends Component {

    constructor(props) {
        super(props);
        this.state = {
            events: []
        };
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        let df = new Date();
        let dt = new Date();

        if (window.dashboardDateRange !== undefined) {
            df = window.dashboardDateRange.startDate;
            dt = window.dashboardDateRange.endDate;
        }

        df = df.getFullYear() + '-' + (
            df.getMonth() + 1) + '-' + df.getDate();
        dt = dt.getFullYear() + '-' + (
            dt.getMonth() + 1) + '-' + dt.getDate();

        cachios.get(`counters/daily-events?df=${df}&dt=${dt}`, { ttl: 300 }).then(res => {
            let counters = res.data.counters;

            counters.forEach((item, k) => {
                counters[k] = {
                    "name": __('leads.event.actions.' + item.name),
                    "data": item.data
                };
            });

            this.setState({ events: counters });

            setTimeout(() => {
                this.loadData();
            }, 1000);

        }).catch(error => { });
    }

    render() {
        return (<div className="dashboard-el__widget dashboard-el__widget--knownanonymous-widget">
            <LineChart data={this.state.events} />
        </div>);
    }
}

export default DailyEventsWidget;
