import React, {
  Component
} from 'react';

import {
  NavLink
} from 'react-router-dom'

import axios from 'axios';

import InfiniteScroll from 'react-infinite-scroll-component';

import InfiniteListLoader from '../../Elements/Loaders/InfiniteListLoader';

import Element from './List/Element';
import Filters from './List/Filters';

import SetPageTitle from '../../Helpers/PageHelpers';

import {
  FaFilter
} from 'react-icons/fa';

class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      isLoading: true,
      hasMore: true,
      cursor: 1,
      filterSearch: '',
      filterCountry: '',
      filterSegment: '',
      filterType: '',
      typing: false,
      typingTimeout: 0,
      fillWhiteSpaceSteps: 0,
      filterDateFrom: new Date(),
      filterDateTo: new Date(),
      listType: 'grid',
      displayFilters: false
    };
  }

  componentDidMount() {
    this.loadList()
    let d = new Date();
    d.setMonth(d.getMonth() - 12);
    d.setDate(d.getDate() + 1);

    this.setState({
      filterDateFrom: d
    });

    SetPageTitle('Leads');


    window.echo.channel('population.alert.' + window.userUuid).listen('PopulationFeed', (event) => {

      let newItem = [event.data.population_item];
      let isNew = true;

      let currentItems = this.state.items.map((item) => {
        if (item.uuid === newItem[0].uuid) {
          isNew = false;
          return newItem[0];
        }
        return item;
      })

      if (!isNew) {
        this.setState({
          items: currentItems
        })
      } else {
        this.setState(state => ({
          items: [
            ...newItem,
            ...currentItems
          ]
        }));
      }

    });
  }

  loadList() {
    let component = this;
    this.setState({
      items: []
    });
    this.setState({
      cursor: 1
    });
    setTimeout(function () {
      component.loadMore();

      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }, 200);

  }

  updateDateFilter(range) {
    this.setState({
      filterDateFrom: range.selection.startDate
    });
    this.setState({
      filterDateTo: range.selection.endDate
    });
    this.loadList();
  }

  updateFilter(key, value) {
    this.setState({
      [key]: value
    });

    this.loadList();
  }

  updateFilters(event) {
    if (event.target.type === 'checkbox') {
      this.setState({
        [event.target.name]: event.target.checked
      });
    } else {
      this.setState({
        [event.target.name]: event.target.value
      });
    }

    const self = this;

    if (event.target.type === 'text') {

      if (self.state.typingTimeout) {
        clearTimeout(self.state.typingTimeout);
      }

      self.setState({
        name: event.target.value,
        typing: false,
        typingTimeout: setTimeout(function () {
          self.loadList();
        }, 350)
      });
    } else {
      setTimeout(function () {
        self.loadList();
      }, 50)
    }
  }

  loadMore = () => {
    this.setState({
      isLoading: true,
      error: undefined
    });

    let dateFrom = this.state.filterDateFrom.getFullYear() + '-' + (
      this.state.filterDateFrom.getMonth() + 1) + '-' + this.state.filterDateFrom.getDate();
    let dateTo = this.state.filterDateTo.getFullYear() + '-' + (
      this.state.filterDateTo.getMonth() + 1) + '-' + this.state.filterDateTo.getDate();

    axios.get(`lead?page=${this.state.cursor}&s=${this.state.filterSearch}&sg=${this.state.filterSegment}&c=${this.state.filterCountry}&t=${this.state.filterType}&df=${dateFrom}&dt=${dateTo}`).then(res => {
      let cursor = this.state.cursor;

      this.setState({
        cursor: (cursor + 1)
      });

      this.setState(state => ({
        items: [
          ...state.items,
          ...res.data
        ],
        isLoading: false
      }));

      if (!res.data.length) {
        this.setState({
          hasMore: true
        });
      }

      this.fillWhiteSpace();

    }).catch(error => {
      this.setState({
        isLoading: false,
        error
      })
    });
  }

  fillWhiteSpace() {

    if (document.getElementById('leads-list').clientHeight < window.innerWidth) {
      if (this.state.fillWhiteSpaceSteps < 5) {
        this.loadMore();
        this.setState({
          fillWhiteSpaceSteps: (this.state.fillWhiteSpaceSteps + 1)
        })
      }
    }
  }

  userChangeView(type) {
    this.setState({
      listType: type
    });

    this.fillWhiteSpace();
  }

  closeFilters() {
    this.setState({
      displayFilters: false
    });
  }
  displayFilters(e) {
    e.preventDefault();

    this.setState({
      displayFilters: true
    });
  }

  render() {
    return (< div className="container-adjustement" >

      <Filters listType={
        this.state.listType
      }
        displayFilters={
          (
            this.state.displayFilters) ?
            'true' : ''
        }
        closeFilters={
          this.closeFilters.bind(this)
        }
        loadList={
          this.loadList.bind(this)
        }
        userChangeView={
          this.userChangeView.bind(this)
        }
        updateFilter={
          this.updateFilter.bind(this)
        }
        filterDateFrom={
          this.state.filterDateFrom
        }
        filterDateTo={
          this.state.filterDateTo
        }
        updateDateFilter={
          this.updateDateFilter.bind(this)
        }
        filterSearch={
          this.state.filterSearch
        }
        filterType={
          this.state.filterType
        }
        updateFilters={
          this.updateFilters.bind(this)
        }
      />

      <button className={
        (
          this.state.displayFilters) ?
          'floating-action-filters -active' : 'floating-action-filters'
      }
        onClick={
          this.displayFilters.bind(this)
        } >
        <FaFilter className="icon" />        </button>

      <div className={
        "container-leads-item container-leads-item--" + this.state.listType
      }
        id="leads-list" >

        <InfiniteScroll loader={
          <
            InfiniteListLoader />
        }
          dataLength={
            this.state.items.length
          }
          next={
            this.loadMore
          }
          throttle={
            100
          }
          threshold={
            300
          }
          isLoading={
            this.state.isLoading
          }
          hasMore={
            this.state.hasMore
          } > {
            this.state.items.length > 0 ?
              this.state.items.map(item => < NavLink className="link-leads-item"
                key={
                  item.uuid
                }
                to={
                  "/leads/" + item.uuid
                } > < Element data={
                  item
                }
                /></NavLink >) : null
          } </InfiniteScroll>

      </div>

    </div>)
  }
}

export default List;