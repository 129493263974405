import React, {Component} from 'react';

import Default from './Menus/Default';
import Profile from './Menus/Profile';
import Configuration from './Menus/Configuration';

class MainMenu extends Component {

  render() {
    if (this.props.menu === 'default') {
      return (<Default/>);
    }
    if (this.props.menu === 'profile') {
      return (<Profile backAction={this.props.backAction}/>);
    }
    if (this.props.menu === 'configuration') {
      return (<Configuration backAction={this.props.backAction}/>);
    }
  }
}

export default MainMenu;
