import React, { Component } from 'react';

import DailyEventsWidget from './Dashboard/Widgets/DailyEventsWidget';
import LiveFeed from './Dashboard/Widgets/LiveFeed';

import __ from '../Helpers/Translate';
import pageTitle from '../Helpers/PageHelpers';

// DATEPICKER
import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file
import { DateRangePicker } from 'react-date-range';



class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterDateFrom: new Date(new Date().setDate(new Date().getDate() - 6)),
      filterDateTo: new Date()
    };

  }

  componentDidMount() {
    pageTitle(__('dashboard.pageTitle'));
  }

  updateDateFilter(range) {
    this.setState({ filterDateFrom: range.selection.startDate });
    this.setState({ filterDateTo: range.selection.endDate });
  }

  render() {
    return (<div className="dashboard-panel">




    </div>);
  }
}

export default Dashboard;
