import React from 'react';
import {ValidatorComponent, ValidatorForm} from 'react-form-validator-core';

class PasswordStrength extends ValidatorComponent {

    constructor(props) {
        super(props)
        this.state = {
            passwordScore: 0,
            passwordScoreMax: 100,
            passwordStrengthMessage: '',
            capital: /[A-Z]/,
            digit: /[0-9]/,
            length: /^.{10,}$/,
            special: /[!@#$%^&*(),.?":{}|<>]/
        }
    }

    passwordStrength() {
        if (this.props.name === 'password') {
            this.state.passwordScore = 10

            if (this.state.capital.test(this.props.value)) {
                this.state.passwordScore = this.state.passwordScore + 10
            }

            if (this.state.digit.test(this.props.value)) {
                this.state.passwordScore = this.state.passwordScore + 10
            }

            if (this.state.length.test(this.props.value)) {
                this.state.passwordScore = this.state.passwordScore + 10
            }

            if (this.state.special.test(this.props.value)) {
                this.state.passwordScore = this.state.passwordScore + 10
            }

            if (this.props.value.length === 0) {
                this.state.passwordScore = 0
            }

            if (this.state.passwordScore === 0) {
                this.state.passwordStrengthMessage = 'score--0'
            }
            if (this.state.passwordScore === 10) {
                this.state.passwordStrengthMessage = 'score--10'
            }
            if (this.state.passwordScore === 20) {
                this.state.passwordStrengthMessage = 'score--20'
            }
            if (this.state.passwordScore === 30) {
                this.state.passwordStrengthMessage = 'score--30'
            }
            if (this.state.passwordScore === 40) {
                this.state.passwordStrengthMessage = 'score--40'
            }
            if (this.state.passwordScore === 50) {
                this.state.passwordStrengthMessage = 'score--50'
            }
        }
    }

    render() {
        const {
            errorMessages,
            validators,
            requiredError,
            validatorListener,
            labelCopy,
            classNameGlobal,
            ...rest
        } = this.props;

        this.passwordStrength()

        ValidatorForm.addValidationRule('isPasswordStrength', (value) => {
            if (value) {
                if (this.state.passwordScore <= 40) {
                    return false;
                }
                return true;
            }

            return true;
        });

        return (<div className={classNameGlobal[0]}>

            <label className={(
                    this.errorText())
                    ? classNameGlobal[1] + ' -error'
                    : classNameGlobal[1]}>{labelCopy}: {this.errorText()}</label>
            <input className={classNameGlobal[2]} {...rest} ref={(r) => {
                    this.input = r;
                }}/>
            <span className="password-meter" data-score={this.state.passwordScore}>
                <span className={'score ' + this.state.passwordStrengthMessage}></span>
            </span>

        </div>)
    }

    errorText() {
        const {isValid} = this.state;

        if (isValid) {
            return null;
        }

        return (<span className="error-message">
            {this.getErrorMessage()}
        </span>);
    }
}

export default PasswordStrength;
