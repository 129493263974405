import React, { Component } from 'react';
import __ from '../../../Helpers/Translate';

import Moment from 'react-moment';

class LiveFeed extends Component {

    constructor(props) {
        super(props);
        this.state = {
            events: []
        };
    }

    componentDidMount() {
        setInterval(() => {
            this.setState({ events: window.liveEvents.slice(Math.max(window.liveEvents.length - 5, 1)).reverse() });


        }, 1000);
    }

    render() {
        return (<div className="dashboard-el__widget dashboard-el__widget--livefeed-widget">
            {(this.state.events.length) ? (this.state.events.map((item) => {
                return (
                    <div className="livefeed-event">
                        <span className="moment"><Moment fromNow>{item.date}</Moment></span>
                        <span className="name">{__('livefeed.' + item.name)}</span>&nbsp;
                        {(item.name === 'new_event_tracked') ? <span className="data">{__('leads.event.actions.' + item.data.action)}</span> : ''}
                    </div>
                )
            })) : <div className="empty">Waiting for events...</div>}
        </div>);
    }
}

export default LiveFeed;
