import React, {Component} from 'react';
import axios from 'axios';

//import __ from '../../Helpers/Translate';

import Edit from './Domains/Edit';
import Item from './Domains/Item';



import SetPageTitle from '../../Helpers/PageHelpers';
import PanelsLoader from '../../Elements/Loaders/PanelsLoader';
//import MessagesHelpers from '../../Helpers/MessagesHelpers';
class Domains extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayEditForm: false,
      loadingForm: false,
      domains: [],
      editingItem: '',
      editingName: '',
      editingUrl:''
    };
  }

  addNew(e) {
    e.preventDefault();
    let self = this;

    self.setState({displayEditForm: false});

    setTimeout(function(){
      self.setState({editingItem: '', displayEditForm: true, editingName: '', editingUrl: ''});
    }, 250);
  }

  closeForm(e){
    e.preventDefault();
    this.setState({
      displayEditForm: false
    });
  }

  componentDidMount() {
    this.loadData();
		SetPageTitle('Domains');
  }

  loadData() {
    this.setState({editingItem: '', displayEditForm: false, editingName: '', editingUrl: '', loadingForm: true});
    axios.get(`domain`).then(res => {
      this.setState({domains: res.data, loadingForm: false});
    }).catch(error => {});
  }

  editItem(uuid) {
    let self = this;
    this.setState({editingItem: '', displayEditForm: false, editingName: '', editingUrl: '', loadingForm: true});

    axios.get(`domain/${uuid}`).then(res => {
      setTimeout(function(){
        self.setState({editingItem: uuid, displayEditForm: true, editingName: res.data.name, editingUrl: res.data.url, loadingForm: false});
			}, 500);
    }).catch(error => {});
  }

	deleteItem(e){

    this.setState({editingItem: '', displayEditForm: false, editingName: '', editingUrl: '', loadingForm: true});
    axios.post(`domain/delete`, {uuid: this.state.editingItem}).then(res => {
      this.setState({editingItem: '', displayEditForm: false, loadingForm: false});
      this.loadData();
    }).catch(error => {});
  }

  handleNameChange(e) {
    e.preventDefault();
    this.setState({editingName: e.target.value});
  }
  handleUrlChange(e) { console.log(e);
    e.preventDefault();
    this.setState({editingUrl: e.target.value});
  }


  render() {
    let self = this;
    return (
      <div className="domains-container domains">
      <ul className="domains-list">

        {
          this.state.domains.map(function(item) {
            return (<li className="domains-list__el" key={item.uuid} onClick={self.editItem.bind(self, item.uuid)}>
              <Item item={item}/>
            </li>)
          })
        }
        <li className="domains-list__el">
          <button className="domains-list__button" onClick={this.addNew.bind(this)}>New</button>
        </li>

      </ul>


        {
          (this.state.displayEditForm)
            ? <Edit handleUrlChange={this.handleUrlChange.bind(this)} handleNameChange={this.handleNameChange.bind(this)} editingName={this.state.editingName} editingUrl={this.state.editingUrl} deleteItem={this.deleteItem.bind(this)} editingItem={this.state.editingItem} refreshList={this.loadData.bind(this)} closeForm={this.closeForm.bind(this)}/>
            : ''
        }

        {
          (this.state.loadingForm)
            ? <PanelsLoader/>
            : ''
        }


      </div>
          );
  }
}

export default Domains;
