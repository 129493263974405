import React, {Component} from 'react';












import { NavLink } from 'react-router-dom'

import {
    FaArrowLeft,
		FaUser,
		FaSignOutAlt
} from 'react-icons/fa';

import __ from '../../Helpers/Translate';



class Profile extends Component {

render(){
    return (


                                          <ul className="nav-aside-right nav-aside-right__el--profile">

                                                  <li className="nav-aside-right__el">
                                                    <button className="link-aside-right -back" onClick={this.props.backAction.bind(this)}>
                                                        <FaArrowLeft />
                                                        <span className="link-aside-right__copy">{__('menu.user.back')}</span>
                                                    </button>
																									</li>

                                                  <li className="nav-aside-right__el">
                                                    <NavLink className="link-aside-right" to="/user">
                                                        <FaUser />
                                                        <span className="link-aside-right__copy">{__('menu.user.account')}</span>
                                                    </NavLink>
																									</li>

																									<li className="nav-aside-right__el">
                                                    <NavLink className="link-aside-right" to="/logout">
                                                        <FaSignOutAlt />
                                                        <span className="link-aside-right__copy">{__('navbar.logout')}</span>
                                                    </NavLink>
                                                  </li>


																						</ul>
																					)
																				}
																				}

																				export default Profile;
