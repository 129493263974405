import React, {Component} from 'react';
import TrackerScript from './Trackers/Script';
import TrackerPixel from './Trackers/Pixel';
import {Route, NavLink, Switch} from 'react-router-dom';

import __ from '../../Helpers/Translate';
import SetPageTitle from '../../Helpers/PageHelpers';
class Trackers extends Component {

    constructor(props) {
        super(props);
        this.state = {
          stepOne: false
        };
    }

	componentDidMount(){
		SetPageTitle('Trackers');
    }

    progressStepOne(){
        this.setState({stepOne: true});
    }

    progressStepTwo(){
        this.setState({stepTwo: true});
    }

    render() {
        return (<div className="trackers-container">
            <div className="trackers-subcontainer trackers">

                <div className="trackers-step">

                    <div className={(this.state.stepOne) ? 'trackers-step-circle -active' : 'trackers-step-circle'}>

                        <div className="trackers-step-circle__container">

                            <span className="trackers-step-circle__number">1</span>

                            <svg className="svg" x="0px" y="0px" viewBox="0 0 67.2 67.2">
                                <path className="check" d="M54.3,18.8L30.8,46.5L13,32.6"/>
                            </svg>
                        </div>

                    </div>

                    <p className="trackers-step__title">{ __('configuration.trackers.choosetracker.title') }</p>
                    <p className="trackers-step__text">{ __('configuration.trackers.choosetracker.subtitle') }</p>

                    <NavLink className={(this.state.stepOne) ? 'trackers-step__link -disable' : 'trackers-step__link'} to="/configuration/trackers/script" onClick={this.progressStepOne.bind(this)}>
                        { __('configuration.trackers.choosetracker.type.js') }
                    </NavLink>
                    <NavLink className={(this.state.stepOne) ? 'trackers-step__link -disable' : 'trackers-step__link'} to="/configuration/trackers/pixel" onClick={this.progressStepOne.bind(this)}>
                        { __('configuration.trackers.choosetracker.type.pixel') }
                    </NavLink>
                </div>

                <Switch>
                    <Route exact="exact" path="/configuration/trackers/script" component={TrackerScript}/>
                    <Route exact="exact" path="/configuration/trackers/pixel" component={TrackerPixel}/>
                </Switch>

            </div>
        </div>);
    }
}

export default Trackers;
