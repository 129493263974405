import React, {Component} from 'react';

import __ from '../../../Helpers/Translate';

import TextValidator from '../../../Elements/Forms/TextValidator';
import {ValidatorForm} from 'react-form-validator-core';

import axios from 'axios';

import Select from 'react-select';

import MessagesHelpers from '../../../Helpers/MessagesHelpers';
import {FaCheck, FaTimes} from 'react-icons/fa';

class Edit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      url: "https://",
      dnsCheck: {}
    };
  }

  componentDidMount() {
    this.loadDomain();
  }

  loadDomain() {
    this.setState({dnsCheck: {}});
    if (this.props.editingItem !== '') {
      axios.get(`domain/${this.props.editingItem}`).then(res => {
        this.setState({name: res.data.name, url: res.data.url, dnsCheck: res.data.dnsCheck});
      }).catch(error => {});
    }
  }

  saveDomain(e) {
    e.preventDefault();
    this.setState({dnsCheck: {}});

    let uuid = '';
    if (this.props.editingItem !== '') {
      uuid = this.props.editingItem;
    }

    let data = {
      'name': this.state.name,
      'url': this.state.url,
      'uuid': uuid
    };

    axios.post(`domain/edit`, {
      name: data.name,
      url: data.url,
      uuid: data.uuid
    }).then(res => {
      MessagesHelpers.pushMessage('success', __('configuration.domains.save.messages.success'));
    }).catch(error => {
      MessagesHelpers.pushMessage('error', __('configuration.domains.save.messages.error'));
    });

    setTimeout(() => {

      this.loadDomain();

      this.props.refreshList();
    }, 750);

  }

  deleteItem(e) {
    e.preventDefault();

    this.props.deleteItem(this.props.editingItem);
  }

  handleNameChange(e) {
    e.preventDefault();
    this.setState({name: e.target.value});
  }

  handleUrlChange(e) {
    e.preventDefault();
    this.setState({url: e.target.value});
    this.setState({dnsCheck: {}});
  }

  changeRegistrar(e) {
    let url = '';
    if (e.value === 'combell') {
      url = 'https://support.combell.com/en/how-do-i-create-a-txt-record/493';
    }
    if (e.value === 'ovh') {
      url = 'https://docs.ovh.com/gb/en/domains/web_hosting_how_to_edit_my_dns_zone/';
    }
    if (e.value === 'cloudflare') {
      url = 'https://support.cloudflare.com/hc/en-us/articles/360019093151-Managing-DNS-records-in-Cloudflare';
    }

    if (url.length) {
      window.open(url, '_blank');
    }
  }

  render() {

    const registrarList = [
      {
        value: 'cloudflare',
        label: "Cloudflare"
      }, {
        value: 'combell',
        label: 'Combell'
      }, {
        value: 'ovh',
        label: 'OVH'
      }
    ];

    return (<div>
      <ValidatorForm className="form-config" ref="form" debounceTime={500} onSubmit={this.saveDomain.bind(this)}>
        <h2 className="form-config__title">Edit a domain</h2>
        <div className="form-config__container-flex">
          <TextValidator onChange={this.handleNameChange.bind(this)} type="text" name="name" placeholder="Name" value={this.state.name} labelCopy="Name" classNameGlobal={["form-config__el", "form-config__label", "form-config__input"]} validators={['required']} errorMessages={[__('error.message.thisfieldisrequired')]}/>

          <TextValidator onChange={this.handleUrlChange.bind(this)} type="text" name="url" placeholder="Host" value={this.state.url} labelCopy="Host (domain name)" classNameGlobal={["form-config__el", "form-config__label", "form-config__input"]} validators={['required']} errorMessages={[__('error.message.thisfieldisrequired'), __('error.message.pleaseinsertacorrecturl')]}/>

        </div>

        <div className="form-config__container-button">
          <button className="form-config__button" type="submit">Save</button>
          {
            (this.props.editingItem !== '')
              ? <button className="form-config__button form-config__button--delete" type="button" onClick={this.deleteItem.bind(this)}>Delete</button>
              : ''
          }
          <button className="form-config__button form-config__button--cancel" onClick={this.props.closeForm.bind(this)}>Cancel</button>
        </div>

      </ValidatorForm>

      {
        (this.state.dnsCheck !== undefined && this.state.dnsCheck.verified !== undefined)
          ? <div className="form-config">
              <h2 className="form-config__title">DNS Verification</h2>
              <div className="form-config__container-flex">
                <div className="form-config__el">
                  <div className="form-config__label">

                    {
                      (this.state.dnsCheck.verified)
                        ? <div className="dns-info__value-check dns-info__value-ok">
                            <FaCheck/>
                            This domain has been verified.
                          </div>
                        : <div className="dns-info__value-check dns-info__value-notok">
                            <FaTimes/>
                            This domain needs a DNS verification.
                          </div>
                    }

                  </div>

                </div>

                {
                  (!this.state.dnsCheck.verified && this.state.dnsCheck.zone !== undefined && this.state.dnsCheck.zone.value !== undefined)
                    ? <div className="form-domain-dns__item">

                        <div>
                          <h2>Please update your DNS settings.</h2>
                          <p>Add a {this.state.dnsCheck.zone.type}
                            entry as follow, and verify your domain again.</p>
                        </div>

                        <table className="dns-table">
                          <tbody>
                            <tr>
                              <td>
                                {this.state.dnsCheck.zone.host}
                              </td>
                              <td>
                                {this.state.dnsCheck.zone.type}
                              </td>
                              <td>
                                {this.state.dnsCheck.zone.value}
                              </td>
                            </tr>
                          </tbody>
                        </table>

                        <div className="dns-help__container">
                          <p>Help needed ? Select your registrar and follow the instructions.</p>
                          <div className="dns-help-registrar">
                            <Select onChange={this.changeRegistrar.bind(this)} options={registrarList}/>
                          </div>
                        </div>
                      </div>
                    : ''
                }

              </div>

              {
                (!this.state.dnsCheck.verified)
                  ? <div className="form-config__container-button">
                      <button onClick={this.loadDomain.bind(this)} className="form-config__button">Verify</button>
                    </div>
                  : ''
              }
            </div>
          : ''
      }

    </div>);
  }
}

export default Edit;
