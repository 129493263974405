import React, {Component} from 'react';
import Icon from '../../../../Elements/Icon';

class DataTable extends Component {
  render() {
    let dataTable = this.props.data;
    if (dataTable !== undefined) {
      if (dataTable !== null) {
        if (dataTable[1] !== undefined) {
          if (Object.keys(dataTable).length) {
            return (<span className="leads-event-user__container-data-table">
              {
                Object.keys(dataTable).map(function(item, k) {
                  if (dataTable[k] !== undefined) {
	                  if (dataTable[k].value !== undefined && dataTable[k].name !== undefined && (typeof dataTable[k].value === "string")) {
                    return (<p className="leads-event-user__data-table" key={k}>
                      <Icon icon={dataTable[k].name}/>

                      <span className="tag-name-no-icon">{dataTable[k].name}:</span> {dataTable[k].value}
                    </p>);
                  }
								}
                  return '';
                })
              }
            </span>);
          }
        }
      }
    }
    return ''
  }

}

export default DataTable;
