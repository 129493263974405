import React, {Component} from 'react';
import LeadNoImage from './LeadNoImage';

class LeadImage extends Component {
  render() {
    if(this.props.lead.image !== undefined){
      return (<img alt="User" className="leads-item__img" src={this.props.lead.image} />)
    } else {
      return (<LeadNoImage colored={true} />)
    }
  }
}

export default LeadImage;
