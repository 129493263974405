import React, { Component } from 'react'
import { NavLink } from 'react-router-dom';
import axios from 'axios'
import CountUp from 'react-countup';
import { SortableContainer, SortableElement, SortableHandle, arrayMove } from 'react-sortable-hoc';

class List extends Component {
  constructor(props) {
    super(props)
    this.state = {
      segments: [],
      counters: [],
      countersWorking: false
    }
  }

  componentDidMount() {
    this.getAllSegments()
  }

  getAllSegments() {
    axios.get(`segment`).then(res => {
      this.setState(state => ({ segments: res.data }));
      this.countSegments();
    }).catch(error => { })
  }

  countSegments() {
    if (!this.state.counters.length) {
      this.countSegment(0);
    }
  }

  countSegment(id) {

    let segments = this.state.segments;

    if (segments[id] !== undefined) {

      let segment = segments[id];

      this.setState({ countersWorking: true });

      let segmentUuid = segment.uuid;

      let popTree = '';
      let popQuery = '';
      if (segment.data !== undefined) {
        if (segment.data.population !== undefined) {
          if (segment.data.population.object !== undefined) {
            popTree = segment.data.population.object;
          }
          if (segment.data.population.query !== undefined) {
            popQuery = segment.data.population.query;
          }
        }
      }

      // Do it directly
      // this.countSegment(id + 1);

      axios.post('action/estimate', {
        population: popTree,
        stringquery: popQuery
      }).then(res => {
        let currentCounters = this.state.counters;
        currentCounters[segmentUuid] = res.data.count;
        currentCounters = {
          ...currentCounters
        };

        this.setState({ countersWorking: false });

        this.setState({ counters: currentCounters });

        // Do it one by one
        this.countSegment(id + 1);

      }).catch(error => { });
    }
  }



  shouldCancelStart(e) {
    // Cancel sorting if the event target is an anchor tag (`a`)
    if (e.target.tagName.toLowerCase() === 'a') {
      return true; // Return true to cancel sorting
    }
  }


  onSortEnd = ({
    oldIndex,
    newIndex
  }, event) => {
    this.setState({
      segments: arrayMove(this.state.segments, oldIndex, newIndex)
    });

    let order = [];
    this.state.segments.forEach((segment) => {
      order.push(segment.uuid);
    })

    axios.post(`segment/sort`, {
      order: order
    }).then(res => {
      // this.getAllSegments();
    }).catch(error => { });
  };


  render() {



    const SortableDargEl = SortableHandle(() => <div className="segments-list-grab">
      <div className="segments-list-grab__el"></div>
    </div>);

    const SortableItem = SortableElement(({ segment, order }) =>

      <li className="segments-el" >  <SortableDargEl /><div className="segments-el__container">


        <p className="segments-el__name">
          {segment.name}
        </p>
        {
          <p className="segments-el__description">

            <span className="color-indicator" style={{
              backgroundColor: (segment.data !== undefined && segment.data !== null && segment.data.color !== undefined)
                ? segment.data.color
                : '#333'
            }}></span>
            <strong>

              {
                (this.state.counters[segment.uuid] !== undefined) ?
                  <CountUp start={0} end={this.state.counters[segment.uuid]} />
                  : '...'
              }  </strong> matching leads
                  </p>
        }

      </div>
        <NavLink className="segments-el__button" key={segment.uuid} to={`/segments/${segment.uuid}`}>Edit</NavLink>

      </li>
    );


    const SortableList = SortableContainer(({ segments }) => {
      return (<ul className="segments-list">
        {
          segments.map((segment, index) => (<SortableItem key={`item-${index}`} index={index} order={index} segment={segment} />))
        }
      </ul>);
    });



    return (<div className="segments-subcontainer">


      {(this.state.segments.length) ?

        <SortableList useDragHandle={true} lockAxis="y" shouldCancelStart={this.shouldCancelStart} segments={this.state.segments} onSortEnd={this.onSortEnd} />
        : ''}
      <NavLink className="button-segments-add" to={`/segments/new`}>
        Create new segment
      </NavLink>
    </div>)
  }
}

export default List
