import React, { Component } from 'react';

import ReactTooltip from 'react-tooltip'

import Moment from 'react-moment';
import 'moment-timezone';

import Icon from '../../../Elements/Icon';
import DataTable from './Event/DataTable';

import countries from 'i18n-iso-countries';
// SVG ICON
import { FaTimes, FaClock, FaGlobe, FaGlobeAfrica, FaMapMarkedAlt } from 'react-icons/fa';

import ImageLoader from 'react-loading-image';


import __ from '../../../Helpers/Translate';
class Element extends Component {
    render() {
        let lang = window.appLang;
        if (lang === undefined) {
            lang = 'en';
        }
        if (lang === '') {
            lang = 'en';
        }
        countries.registerLocale(require("i18n-iso-countries/langs/" + lang + ".json"));

        return (<div className="leads-event-user">

            {
                (this.props.data.tags[0] !== undefined && this.props.data.tags[0] !== null)
                    ? <div className="tag-bar" style={{
                        background: this.props.data.tags[0].color
                    }} ></div>
                    : ''
            }
            <div className="leads-event-user__container-head">
                <h3 className="leads-event-user__vue-type" style={{
                    color: this.props.actionColor
                }}>
                    {__('leads.event.actions.' + this.props.data.action)}



                    <span className="leads-event-user__score-tag">
                        {
                            this.props.data.tags.map((tag) => {
                                if (tag !== undefined && tag !== null && tag.name !== undefined && tag.name !== null && tag.color !== undefined && tag.color !== null) {
                                    return (
                                        <span data-tip={tag.name} className="leads-event-user__score-color" style={{
                                            background: tag.color
                                        }}>
                                        </span>
                                    )
                                } return ''
                            })
                        }
                    </span>

                </h3>
            </div>
            <div className="leads-event-user__container leads-event-user__container--moment">
                <div>
                    <FaClock />
                    <Moment className="leads-event-user__date" format="D MMM YYYY HH:mm" locale="en">{this.props.data.created_at}</Moment>
                </div>
                <Moment className="leads-event-user__time" locale={window.appLang} fromNow={true}>{this.props.data.created_at}</Moment>
            </div>

            <div className="leads-event-user__container leads-event-user__container--url">
                {
                    (this.props.data !== null)
                        ? (this.props.data.url !== null)
                            ? <p className="leads-event-user__url">

                                <FaGlobe /> {this.props.data.url}
                                {
                                    (this.props.data !== undefined)
                                        ? (this.props.data.data !== undefined)
                                            ? (this.props.data.data !== null)
                                                ? (this.props.data.data.zone !== undefined)
                                                    ? <span className="zone">
                                                        ({__('leads.event.details.zone')}: {this.props.data.data.zone})
                                                            </span>
                                                    : ''
                                                : ''
                                            : ''
                                        : ''
                                }

                            </p>

                            : ''
                        : ''
                }
                {
                    (this.props.data !== undefined)
                        ? (this.props.data !== null)
                            ? (this.props.data.data !== undefined)
                                ? (this.props.data.data !== null)
                                    ? <DataTable data={this.props.data.data} />
                                    : ''
                                : ''
                            : ''
                        : ''
                }
            </div>

            <div className="leads-event-user__container">
                <p className="leads-event-user__device-type">
                    {
                        (this.props.data.device.type !== undefined)
                            ? <Icon icon={this.props.data.device.type} />
                            : ''
                    }
                    {/* <span className="leads-event-user__subtitle">Type:</span> */}
                    {__('global.device.type.' + this.props.data.device.type)}
                </p>
                <p className="leads-event-user__device-system">
                    {
                        (this.props.data.device.system !== undefined)
                            ? <Icon icon={this.props.data.device.system} />
                            : ''
                    }
                    {/* <span className="leads-event-user__subtitle">System:</span> */}
                    {__('global.device.system.' + this.props.data.device.system)}
                </p>
                <p className="leads-event-user__device-browser">
                    {
                        (this.props.data.device.system !== null)
                            ? <Icon icon={this.props.data.device.browser} />
                            : <Icon icon="browser" />
                    }
                    {/* <span className="leads-event-user__subtitle">Browser:</span> */}
                    {
                        (this.props.data.device.browser !== undefined)
                            ? __('global.device.browser.' + this.props.data.device.browser)
                            : ''
                    }
                </p>
            </div>

            <div className="leads-event-user__container">
                <p className="leads-event-user__country">
                    <FaGlobeAfrica /> {/* <span className="leads-event-user__subtitle">Country:</span> */}
                    {countries.getName(this.props.data.location.country, window.appLang)}
                </p>
                <p className="leads-event-user__city">
                    <FaMapMarkedAlt /> {/* <span className="leads-event-user__subtitle">City:</span> */}
                    {this.props.data.location.city}
                </p>
            </div>
            {/* <div className="leads-event-user__container leads-event-user__container--ip-tag">
                <p className="leads-event-user__ip">
                    <span className="icon"></span>{this.props.data.ip}</p>





            </div> */}

            <ReactTooltip delayShow="500" place="left" type="light" effect="solid" />

        </div>);
    }
}

export default Element;
