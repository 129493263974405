import React, {Component} from 'react';

import List from './Plugins/List';
import ConfigurePlugin from './Plugins/ConfigurePlugin';

import {Route, Switch} from 'react-router-dom';
class Plugins extends Component {

    render() {
        return (<div className="plugins-container plugins">

            <Switch>
                <Route exact={true} path="/configuration/plugins" component={List}/>
                <Route exact={true} path="/configuration/plugins/:sysname" component={ConfigurePlugin}/>
            </Switch>

        </div>);
    }
}

export default Plugins;
