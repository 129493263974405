import React, {Component} from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import cachios from 'cachios';

import Select from 'react-select';

import __ from '../../../Helpers/Translate';
class TrackerScript extends Component {

  constructor(props) {
    super(props);
    this.state = {
      script: [],
      copied: false,
      tags: []
    };
    this.changeTag = this.changeTag.bind(this);
  }

  componentDidMount() {
    this.getScript();
    this.getTags();
  }

  getScript() {
    cachios.get(`generator`).then(res => {
      this.setState(state => ({script: res.data.web}));
    }).catch(error => {});
  }

  changeTag(event) {
    let tags = [];
    event.forEach((item) => {
      tags.push(item.value);
    });

    tags = tags.join(',');

    cachios.get(`generator?tag=${tags}`).then(res => {
      this.setState(state => ({script: res.data.web}));
    }).catch(error => {});
  }

  getTags() {
    cachios.get('tag').then(res => {

      let tags = [];

      res.data.forEach(function(tag) {
        tags.push({'value': tag.uuid, 'label': tag.name});
      });

      this.setState({tags: tags});
    }).catch(error => {});
  }

  // sguen(){
  //     console.log(this.state.tags);
  // }

  render() {
    return (<div>
      <div className="trackers-step trackers-step--last-step">

        <div className={(
            this.state.copied)
            ? 'trackers-step-circle -active'
            : 'trackers-step-circle'}>

          <div className="trackers-step-circle__container">

            <span className="trackers-step-circle__number">2</span>

            <svg className="svg" x="0px" y="0px" viewBox="0 0 67.2 67.2">
              <path className="check" d="M54.3,18.8L30.8,46.5L13,32.6"/>
            </svg>
          </div>

        </div>

        <p className="trackers-step__title">{ __('configuration.trackers.jshead.title') }</p>
        <p className="trackers-step__text">{ __('configuration.trackers.jshead.subtitle') }</p>

        {
          this.state.script.script
            ? <p className="trackers-script">{this.state.script.script}</p>
            : null
        }

        <CopyToClipboard text={this.state.script.script} onCopy={() => this.setState({copied: true})}>
          <button className="trackers-step__link">{ __('configuration.trackers.general.copyclipboard') }</button>
        </CopyToClipboard>

        {
          this.state.copied
            ? <span className="trackers-step__copied">Copied!</span>
            : null
        }
      </div>

      <div className="trackers-step trackers-step--last-step trackers-metatags">
        <p className="trackers-step__title">{ __('configuration.trackers.metatags.title') }</p>
        <p className="trackers-step__text">{ __('configuration.trackers.metatags.subtitle') }</p>

        <Select isMulti={true} className="select-filters react-select-container" onChange={this.changeTag} value={this.state.tags.uuid} options={this.state.tags}/> {
          (this.state.script.tags!==undefined && this.state.script.tags !== '')
            ? <div>

                <p className="trackers-script">{this.state.script.tags}</p>

                <CopyToClipboard text={this.state.script.tags} onCopy={() => this.setState({copied: true})}>
                  <button className="trackers-step__link">{ __('configuration.trackers.general.copyclipboard') }</button>
                </CopyToClipboard>
              </div>

            : ''
        }

      </div>
    </div>);

  }
}

export default TrackerScript;
