import React, { Component } from 'react';
import { Query, Builder, Utils as QbUtils } from 'react-awesome-query-builder';
import config from './config';

import axios from 'axios';
import cachios from 'cachios';

import countries from 'i18n-iso-countries'
const transit = require('transit-immutable-js');

class QueryBuilder extends Component {

  constructor(props) {
    super(props);
    this.state = {
      tags: [],
      segments: [],
      tagsLoaded: false,
      segmentsLoaded: false,
      countries: [],
      countriesLoaded: false
    };
  }

  componentDidMount() {
    axios.get(`tag`).then(res => {
      this.setState({ tags: res.data, tagsLoaded: true });
    }).catch(error => { });

    axios.get(`datasets/country`).then(res => {
      this.setState({ countries: res.data, countriesLoaded: true });
    }).catch(error => { });

    axios.get(`segment`).then(res => {
      this.setState({ segments: res.data, segmentsLoaded: true });
    }).catch(error => { });
  }

  getChildren(props) {
    return (<div>
      <div className="query-builder">
        <Builder {...props} />
      </div>
    </div>)
  }

  onChange(config, tree) {
    this.props.updatePopulation({
      serialized: transit.toJSON(tree),
      query: QbUtils.queryString(tree, config),
      object: tree
    });
  }

  render() {

    if (config !== undefined && this.state.segments.length && this.state.countries.length && this.state.tags.length) {
      let listSegments = [];

      this.state.segments.forEach((item) => {
        listSegments[item.uuid] = item.name;
      });

      listSegments = {
        ...listSegments
      };
      config.fields.segment.listValues = listSegments;




      let listCountries = [];

      let lang = 'en'
      if (window.appLang !== '' && window.appLang !== undefined) {
        lang = window.appLang
      }
      countries.registerLocale(require("i18n-iso-countries/langs/" + lang + ".json"))
      this.state.countries.forEach((item) => {
        listCountries[item.key] = countries.getName(item.value, lang);
      });

      listCountries = {
        ...listCountries
      };

      config.fields.country.listValues = listCountries;








      let listTags = [];

      this.state.tags.forEach((item) => {
        listTags[item.uuid] = {
          label: item.name,
          type: 'number',
          operators: ["less", "less_or_equal", "greater", "greater_or_equal", "between"]
        };
      });
      listTags = {
        ...listTags
      };

      config.fields.scoring.subfields = listTags;
    }

    let value = null;
    if (this.props.population !== undefined && this.props.population !== null) {
      if (this.props.population.serialized !== null) {
        if (typeof this.props.population.serialized === 'string') {
          value = transit.fromJSON(this.props.population.serialized);
        }
      }
    }

    return (<div className="query-builder">
      {
        (this.state.tagsLoaded && this.state.segmentsLoaded && this.state.countriesLoaded)
          ? <Query {...config} value={value} tags={this.state.tags} segments={this.state.segments} get_children={this.getChildren} onChange={this.onChange.bind(this, config)}></Query>

          : ''
      }

    </div>);
  }
}

export default QueryBuilder;
