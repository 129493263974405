import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { CheckPermissions } from '../Helpers/Permission';

import List from './Segments/List';
import Details from './Segments/Details';

import pageTitle from '../Helpers/PageHelpers';
class Segments extends Component {
    componentDidMount() {
        pageTitle('Segments');
    }

    render() {
        return (<div className="segments-container">

            <Switch>
                {CheckPermissions('access_segments') && <Route exact={true} path="/segments" component={List} />}
                {CheckPermissions('access_segments') && <Route exact={true} path="/segments/:segment" component={Details} />}
            </Switch>

        </div>);
    }
}

export default Segments
