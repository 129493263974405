import React from 'react';
import Select from 'react-select'
import { ValidatorComponent } from 'react-form-validator-core';
 
class SelectValidator extends ValidatorComponent {
 
    render() {
        const { errorMessages, validators, requiredError, validatorListener, labelCopy, classNameGlobal, ...rest } = this.props;
 
        return (

            <div className={(this.errorText())? classNameGlobal[0]+' -error': classNameGlobal[0]}>
                <label className={classNameGlobal[1]}>{labelCopy}:  {this.errorText()}</label>
                <Select className={classNameGlobal[2]} classNamePrefix="react-select" {...rest}/>
            </div>

        );
    }
 
    errorText() {
        const { isValid } = this.state;
 
        if (isValid) {
            return null;
        }
 
        return (
            <span className="error-message">
                {this.getErrorMessage()}
            </span>
        );
    }
}
 
export default SelectValidator;