import React, {Component} from 'react';

import Moment from 'react-moment';
import 'moment-timezone';

import axios from 'axios';

class Notifications extends Component {
    actionRedirect(method) {
        if (method === 'configuration.trackingCode') {
            window.location.href = '/configuration/trackers';
            return;
        }
        if (method === 'leads') {
            window.location.href = '/leads';
            return;
        }
        if (method === 'configuration/domains') {
            window.location.href = '/configuration/domains';
            return;
        }

    }

    actionApi(method) {
        axios.get(method).then(res => {
            this.props.hideNotifications();
        });
    }

    setAsRead(uuid) {
        axios.post('notification/read', {uuid: uuid}).then(res => {
            this.props.hideNotifications();
        });
    }

    render() {
        let self = this;

        return (<div className="notif-list">

            {
                (this.props.notifications !== undefined)
                    ? (this.props.notifications !== null)
                        ? (this.props.notifications !== false)
                            ? this.props.notifications.map(function(item) {
                                return (<div className={(
                                        item.read)
                                        ? 'notif-el notif-el--read'
                                        : 'notif-el'} key={item.uuid} onClick={self.setAsRead.bind(self, item.uuid)} data-read={item.read}>
                                    <div className="notif-el__container">
                                        <p className="notif-el__title">
                                            {item.title}
                                        </p>
                                        <p className="notif-el__description">
                                            {item.description}
                                        </p>

                                        <Moment className="notif-el__moment" fromNow={true} locale="en">{item.date}</Moment>
                                    </div>

                                    {
                                        (item.data !== null && !item.read)
                                            ? (item.data.actions !== null)
                                                ? <div className="notif-el__container">
                                                        {
                                                            item.data.actions.map(function(action) {
                                                                if (action.methodType === "redirect") {
                                                                    return (<button className={"notif-el__button notif-el__button--" + action.id} onClick={self.actionRedirect.bind(self, action.method)}>{action.label}</button>)
                                                                }
                                                                if (action.methodType === "apiCall") {
                                                                    return (<button className={"notif-el__button notif-el__button--" + action.id} onClick={self.actionApi.bind(self, action.method)}>{action.label}</button>)
                                                                }
                                                                return true;
                                                            })
                                                        }
                                                    </div>
                                                : ''
                                            : ''
                                    }
                                </div>)
                            })
                            : ''
                        : ''
                    : ''
            }

        </div>);
    }
}

export default Notifications;
