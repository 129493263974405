import React, {Component} from 'react';
import {FaInfoCircle, FaCheck, FaExclamationTriangle, FaTimesCircle} from 'react-icons/fa';

class Popup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            display: false,
            willClose: false
        }
    }

    removeMessage() {
        this.setState({display: false, willClose: false});
        setTimeout(() => {
            this.props.removeMessage();
        }, 1000);
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({display: true});
        }, 250);

        setTimeout(() => {
            this.setState({willClose: true});
            setTimeout(() => {
                this.removeMessage();
            }, 3000);
        }, 9000);
    }

    render() {
        return (
        // <div className={ "message-popup message-popup--" + ( this.props.styleName || "") }>
        <div className={(
                (this.state.display)
                ? '-active'
                : '') + ' ' + (
                (this.state.willClose)
                ? '-willClose'
                : '') + " message-popup message-popup--" + (
                this.props.styleName
                ? this.props.styleName
                : "success") + (this.props.classNameAdd || "")}>

            {
                (this.props.styleName)
                    ? ""
                    : <FaCheck className="message-popup__icon"/>
            }

            {
                (this.props.styleName === "infos")
                    ? <FaInfoCircle className="message-popup__icon"/>
                    : ''
            }
            {
                (this.props.styleName === "success")
                    ? <FaCheck className="message-popup__icon"/>
                    : ''
            }
            {
                (this.props.styleName === "warning")
                    ? <FaExclamationTriangle className="message-popup__icon"/>
                    : ''
            }
            {
                (this.props.styleName === "error")
                    ? <FaTimesCircle className="message-popup__icon"/>
                    : ''
            }

            <p className="message-popup__copy">{this.props.children}</p>

            <button onClick={this.removeMessage.bind(this)} className="message-popup__cross"></button>

        </div>);
    }
}

export default Popup;
