import React, {Component} from 'react';

// import {ContentLoader} from 'react-loaders-spinners';

class PanelsLoader extends Component {
  render() {
    return (<div className="panels-loader">
      <div className="panels-loader__title"></div>
      <div className="panels-loader__container">
        <div className="panels-loader__el">
          <div className="panels-loader__label"></div>
          <div className="panels-loader__input"></div>
        </div>
        <div className="panels-loader__el">
          <div className="panels-loader__label"></div>
          <div className="panels-loader__input"></div>
        </div>
      </div>
      <div className="panels-loader__button"></div>
      <div className="panels-loader__button panels-loader__button--delete"></div>
      <div className="panels-loader__button panels-loader__button--cancel"></div>

      {/* <ContentLoader
        rows={3}
        width={200}
        lineHeight={30}
      /> */}

    </div>);
  }
}

export default PanelsLoader;
