import React, {Component} from 'react';
import pageTitle from '../Helpers/PageHelpers';
import Team from './User/Team';
import Account from './User/Account';
import Organization from './User/Organization';
import Default from './User/Default';
import {Route, NavLink, Switch} from 'react-router-dom';
import {CheckPermissions} from '../Helpers/Permission';

import SubNav from '../Elements/Menus/SubNav';

import __ from '../Helpers/Translate';
class User extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: []
        }
        pageTitle(__('user.pageTitle'));
    }
    // handleMessageFormUpdate(){
    //     this.props.messageFormUpdate()
    // }

    render() {
        return (<div className="profile-container configuration">

            {/* <h1 className="title-main">{__('user.pageTitle')}</h1> */}
            <SubNav>
                {
                    CheckPermissions('configure_organization') && <li className="subnav-list__el">
                            <NavLink className="subnav-list__link" to="/user/organization">{__('user.subnav.organization')}</NavLink>
                        </li>
                }
                <li className="subnav-list__el">
                    <NavLink className="subnav-list__link" to="/user/account">{__('user.subnav.account')}</NavLink>
                </li>
                {
                    CheckPermissions('access_team') && <li className="subnav-list__el">
                            <NavLink className="subnav-list__link" to="/user/team">{__('user.subnav.team')}</NavLink>
                        </li>
                }
            </SubNav>
            {/* <button onClick={this.props.messageFormUpdate.bind(this)}></button> */}

            <Switch>
                {CheckPermissions('configure_organization') && <Route exact={true} path="/user/organization" component={Organization}/>}
                <Route exact={true} path="/user/account" component={Account}/>
                <Route exact={true} path="/user/team/new" component={Account}/> {CheckPermissions('access_team') && <Route exact={false} path="/user/team" component={Team}/>}
                <Route exact={true} path="/user" component={Default}/>
            </Switch>

        </div>);
    }
}

export default User;
