import React, { Component } from 'react';
import { CheckPermissions } from './Helpers/Permission';

import { BrowserRouter, Route, Redirect, NavLink, Switch } from 'react-router-dom'

import Echo from "laravel-echo"
import Socketio from 'socket.io-client';

import axios from 'axios';
import { loadProgressBar } from 'axios-progress-bar';
import 'axios-progress-bar/dist/nprogress.css';

import Cookies from 'js-cookie';
import StickyBox from "react-sticky-box";

import Conf from './Conf';

import MainMenu from './Components/MainMenu';
import Notifications from './Components/Notifications';
import Leads from './Components/Leads';
import Segments from './Components/Segments';
import Actions from './Components/Actions';
import Tags from './Components/Tags';
import Scoring from './Components/Scoring';
import DashboardGrid from './Components/Dashboard';
import NoMatch from './Components/NoMatch';
import Login from './Components/Login';
import Configuration from './Components/Configuration';
import User from './Components/User';
import TooManyRequests from './Elements/TooManyRequests';
import Breadcrumbs from './Elements/Breadcrumbs';
import Messages from './Elements/Messages';

import Footer from './Components/Footer';

import WaitInit from './Components/WaitInit';

import { FaCogs, FaBell, FaUserCircle, FaArrowLeft } from 'react-icons/fa';

import './Assets/sass/styles.scss';
import NotificationSound from './Assets/sounds/notification.mp3';

import { ToastContainer } from 'react-toastify';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logged: null,
      loginError: false,
      loginErrorMessage: '',
      loginLoading: false,
      appClasses: [],
      user: false,
      unreadNotifications: 0,
      displayNotifications: false,
      displaypProfileNavContainer: false,
      notifications: false,
      notificationNew: false,
      apiBlocked: false,
      navAsideClose: false,
      previousUrl: '',
      mainmenu: 'default',
      appInit: false,
    };
  }

  componentDidMount() {
    this.initApp();
  }


  initApp() {

    let self = this;
    this.setClasses();
    let cookieToken = Cookies.get('logged');
    if (cookieToken !== undefined && cookieToken !== '') {
      this.setState({ logged: cookieToken });
    }

    this.setCallsDefault(cookieToken);

    if (cookieToken !== undefined && cookieToken) {
      axios.get('user').then(res => {
        this.setState({ user: res.data });

        window.userPermissions = this.state.user.permissions
        window.userUuid = this.state.user.account.uuid

        if (res.data.lang !== '' && res.data.lang !== undefined) {
          window.appLang = res.data.lang;
        } else {
          window.appLang = 'en';
        }

        if (this.state.user.account !== undefined) {
          let echo = new Echo({
            broadcaster: 'socket.io',
            host: Conf.socketBaseDomain + ':' + Conf.socketPort,
            client: Socketio
          }); window.echo = echo;


          window.echo.channel('notification.alert.' + window.userUuid).listen('Notify', function (event) {
            self.loadNotifications();
          });

          // window.echo.channel('livefeed.alert.' + window.userUuid).listen('LiveFeed', function (event) {
          //   self.liveFeedPush(event);
          // });

          window.echo.channel('user.refresh.' + window.userUuid).listen('UserRefresh', function (event) {
            self.reloadUser();
          });

          setTimeout(() => {
            this.setState({ appInit: true });
          }, 1000);

        }
      }).catch(error => {
        this.logoutAction();
      });;
    }

    self.loadNotifications();

    //ReactGA.initialize('UA-128969506-1');

    //this.setAnalytics();

  }

  reloadUser() {
    axios.get('user').then(res => {
      this.setState({ user: res.data });
    });
  }

  setCallsDefault(forceToken) {
    let self = this;

    if (forceToken === undefined) {
      forceToken = this.state.logged;
    }

    axios.defaults.baseURL = Conf.apiBaseDomain;

    axios.defaults.headers.common['Accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = 'application/json';

    if (forceToken) {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + forceToken;
    } else {
      delete axios.defaults.headers.common['Authorization'];
    }

    axios.interceptors.response.use(res => {
      return res;
    }, err => {
      if (err.response.status === 429) {
        self.setState({ apiBlocked: true });

        setTimeout(function () {
          self.setState({ apiBlocked: false });

          document.location.reload();
        }, 10000);
      }
      return Promise.reject(err);
    });
  }

  setClasses() {
    let newClasses = [];

    newClasses.push('app');
    if (this.state.logged) {
      newClasses.push('logged');
    } else {
      newClasses.push('not-logged');
    }

    this.setState({ appClasses: newClasses });
  }

  loginAction(mail, password) {
    let component = this;

    component.setState({ loginLoading: true });

    delete axios.defaults.headers.common['Authorization'];

    axios.post('login', {
      email: mail,
      password: password
    }).then(res => {
      let token = res.data.success.token;
      Cookies.set('logged', token);
      component.setState({ logged: token });
      component.setState({ loginError: false, loginErrorMessage: '' });
      component.setState({ loginLoading: false });
      component.setCallsDefault();
      document.location.reload();
    }).catch(error => {
      component.setState({ loginError: true, loginErrorMessage: 'badloginorpass' });
      component.setState({ loginLoading: false });
    });
  }

  logoutAction(event) {
    this.setState({ logged: false });
    Cookies.remove('logged');

    this.setCallsDefault();
    window.location.href = '/';
    window.history.push('/');
    document.location.reload();
  }

  loadNotifications() {
    let self = this;
    axios.get('notification').then(res => {
      let currNotifications = this.state.notifications;
      let newNotifications = res.data;
      this.setState({ notifications: res.data });
      let unread = 0;
      res.data.map(function (item) {
        if (!item.read) {
          unread = unread + 1;
        }
        return true;
      });

      this.setState({ unreadNotifications: unread });

      if (this.state.unreadNotifications <= unread && unread > 0 && currNotifications !== newNotifications) {

        self.setState({ notificationNew: true });
        setTimeout(function () {
          self.setState({ notificationNew: false });
        }, 2000);

        new Audio(NotificationSound).play();
      }

    });
  }

  toggleNotifications(e) {
    e.preventDefault(e);
    this.setState({
      displayNotifications: !this.state.displayNotifications
    });
  }

  toggleProfileNav(e) {
    e.preventDefault(e);
    e.stopPropagation(e);
    this.setMenu('profile');
  }

  setMenu(menu) {
    this.setState({ mainmenu: menu });
  }

  hideNotifications(e) {
    this.setState({ displayNotifications: false });
  }

  toggleNavAside() {
    this.setState({
      navAsideClose: !this.state.navAsideClose
    });
  }

  render() {
    loadProgressBar();
    return (<div className={(
      this.state.logged)
      ? "logged"
      : "not-logged"}>
      {

        (!this.state.logged)

          ? <Login loading={this.state.loginLoading} error={this.state.loginError} errorMessage={this.state.loginErrorMessage} loginAction={this.loginAction.bind(this)} />

          : (this.state.appInit)
            ? <BrowserRouter>
              <div className={(
                this.state.navAsideClose)
                ? "app-inner -collapse"
                : "app-inner"}>

                <aside className="aside">
                  <StickyBox>
                    <nav className="nav-aside-container">

                      <ul className="nav-aside-left">

                        <li className="nav-aside-left__el nav-aside-left__el--flex-grow"></li>

                        <li className="nav-aside-left__el">
                          <NavLink onClick={this.toggleNotifications.bind(this)} className={(
                            this.state.notificationNew)
                            ? 'link-aside-left link-aside-left--notif -blink'
                            : 'link-aside-left link-aside-left--notif'} to="/">
                            {
                              (this.state.unreadNotifications !== 0)
                                ? <span className="link-aside-left__number-notif nav-link__number-notif">{this.state.unreadNotifications}</span>
                                : ''
                            }

                            <FaBell />
                          </NavLink>

                          <div className={(
                            this.state.displayNotifications)
                            ? 'container-notif -display'
                            : 'container-notif'}>

                            <div className="container-notif__container container-notif__container--aside">
                              <button className="container-notif__btn-back" onClick={this.hideNotifications.bind(this)}><FaArrowLeft /></button>
                            </div>

                            <div className="container-notif__container">

                              <h2 className="container-notif__title">Notifications</h2>
                              <Notifications hideNotifications={this.hideNotifications.bind(this)} loadNotifications={this.loadNotifications.bind(this)} notifications={this.state.notifications} />

                            </div>

                          </div>

                        </li>

                        <li className="nav-aside-left__el nav-aside-left__el--profile">

                          <NavLink to="/user" className="link-aside-left link-aside-left--profile" activeClassName="-active" onClick={this.setMenu.bind(this, 'profile')}>
                            <FaUserCircle />
                          </NavLink>

                        </li>

                        <li className="nav-aside-left__el nav-aside-left__el--profile">
                          <NavLink activeClassName="-active" className="link-aside-left link-aside-left--configuration" to="/configuration" onClick={this.setMenu.bind(this, 'configuration')}>
                            <FaCogs />
                          </NavLink>
                        </li>

                      </ul>

                      <MainMenu backAction={this.setMenu.bind(this, 'default')} menu={this.state.mainmenu} />

                    </nav>

                    <div className={(
                      this.state.displayNotifications)
                      ? 'overlay-notification -display'
                      : 'overlay-notification'} onClick={this.hideNotifications.bind(this)}></div>

                    {/* </div> */}

                  </StickyBox>
                </aside>

                <main className="main-container">

                  <Messages navAsideClose={this.state.navAsideClose} />

                  <Breadcrumbs />

                  <Switch>
                    <Redirect exact={true} path="/" to="/dashboard" /> {CheckPermissions('access_dashboard') && <Route exact={true} path="/dashboard" component={DashboardGrid} />}
                    {CheckPermissions('access_actions') && <Route path="/actions" component={Actions} />}
                    {CheckPermissions('access_leads') && <Route path="/leads" component={Leads} />}
                    {CheckPermissions('access_segments') && <Route path="/segments" component={Segments} />}
                    {CheckPermissions('configure_rules') && <Route path="/scoring" component={Scoring} />}

                    <Route path="/user" component={User} />
                    <Route path="/configuration" component={Configuration} />
                    <Route path="/tags" component={Tags} />
                    <Route path="/logout">
                      {this.logoutAction.bind(this)}
                    </Route>
                    <Route component={NoMatch} />
                  </Switch>

                  {/* <Footer /> */}
                </main>

              </div>
            </BrowserRouter>
            : <WaitInit />
      }

      {
        (this.state.apiBlocked)
          ? <TooManyRequests />
          : ''
      }

      <ToastContainer />
    </div>);
  }
}

export default App;
