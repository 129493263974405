import React, { Component } from 'react';
import pageTitle from '../Helpers/PageHelpers';


class WaitInit extends Component {
	componentDidMount() {
		pageTitle(('Loading...'));
	}

	render() {
		return (
			<div className="app-login">
				<div className="form-login"><div className="form-el">
					<div className="circleLoader"><div></div></div></div>
				</div>
			</div>
		);
	}
}

export default WaitInit;
