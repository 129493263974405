import React, { Component } from 'react';

import axios from 'axios';
import cachios from 'cachios';

import { NavLink } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';

import InfiniteTimelineLoader from '../../Elements/Loaders/InfiniteTimelineLoader';

import Event from './Details/Event';

import LeadName from '../../Elements/Lead/LeadName';
import LeadCity from '../../Elements/Lead/LeadCity';
import LeadNoImage from '../../Elements/Lead/LeadNoImage';
import LeadImage from '../../Elements/Lead/LeadImage';

import Icon from '../../Elements/Icon';

import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

import __ from '../../Helpers/Translate';

import Scores from './Details/Scores';

import StickyBox from "react-sticky-box";

import SetPageTitle from '../../Helpers/PageHelpers';
import { FaTag } from 'react-icons/fa';


import Element from './List/Element';

class Details extends Component {
    constructor(props) {
        super(props);
        this.state = {
            uuid: '',
            events: [],
            isLoading: true,
            hasMore: true,
            cursor: 1,
            lead: null,
            lookalikes: null,
            actionColors: {
                pageview: '#0078FF',
                zoneview: '#0078FF',
                click: '#2980b9',
                form: '#e74c3c',
                mailview: '#27ae60',
                identification: '#f39c12'
            }
        };
    }

    componentDidMount() {
        let pathParts = document.location.pathname.split('/');
        let uuid = pathParts[pathParts.length - 1];

        this.setState({ uuid: uuid });
        this.loadMore(uuid);

        axios.get(`lead/${uuid}`).then(res => {
            this.setState({ lead: res.data });
            SetPageTitle('Lead');

            // cachios.get(`lead/${uuid}/lookalikes`).then(res => {
            //     this.setState({ lookalikes: res.data });
            // }).catch(error => { });
        }).catch(error => { });

    }

    reload() {
        let self = this;
        this.setState({ events: [], isLoading: true, hasMore: true, cursor: 1 });
        setTimeout(function () {
            self.loadMore();
        }, 750);
    }

    loadMore = (uuid) => {
        if (uuid === undefined) {
            uuid = this.state.uuid;
        }

        this.setState({ isLoading: true, error: undefined });

        axios.get(`lead/${uuid}/event?page=${this.state.cursor}`).then(res => {
            let cursor = this.state.cursor;
            this.setState({
                cursor: (cursor + 1)
            });

            this.setState(state => ({
                events: [
                    ...state.events,
                    ...res.data
                ],
                isLoading: false
            }));

            if (!res.data.length) {
                this.setState({ hasMore: false });
            }
        }).catch(error => {
            this.setState({ isLoading: false, error })
        });
    }

    render() {
        return (<div className="container-adjustement lead-details__container">
            <div className="container-list-filters" >
                <StickyBox className="list-filters__box" offsetTop={0}>
                    <div className="leads-item leads-item--user">

                        <div className="leads-item__container leads-item__container--user">
                            {
                                (this.state.lead !== null)
                                    ? (this.state.lead.knownLead !== undefined)
                                        ? (this.state.lead.knownLead.image !== undefined)
                                            ? <LeadImage lead={this.state.lead.knownLead} />
                                            : <LeadNoImage />
                                        : <LeadNoImage />
                                    : <LeadNoImage />
                            }
                            <div className="leads-item__subcontainer leads-item__subcontainer--infos">
                                <p className="leads-item__name">
                                    {
                                        (this.state.lead !== null)
                                            ? (this.state.lead.knownLead !== undefined)
                                                ? <LeadName lead={this.state.lead.knownLead} />
                                                : __('leads.info.anonymous')
                                            : ''
                                    }
                                </p>

                            </div>
                        </div>
                        <div className="leads-item__container leads-item__container--last-seen">
                            <div className="leads-item__subcontainer">

                                {
                                    (this.state.lead !== null && this.state.lead.lastSeen !== undefined)
                                        ? <span className="leads-item__place">
                                            <LeadCity lead={this.state.lead} />
                                        </span>
                                        : ''
                                }

                            </div>
                        </div>

                    </div>
                    <div className="container-leads-filters-user">

                        <Scores lead={this.state.lead} /> {
                            (this.state.lead !== null)
                                ? <ul className="leads-segments-user">

                                    {
                                        (this.state.lead.segments !== null)
                                            ? this.state.lead.segments.map(segment => {
                                                if (segment !== null) {
                                                    return (<li key={segment.uuid} className="leads-segments-user__el">
                                                        <NavLink className="leads-segments-user__link" key={segment.uuid} to={"/leads?sg=" + segment.uuid}>
                                                            <FaTag /> {segment.name}
                                                        </NavLink>
                                                    </li>)
                                                }
                                                return ''
                                            })
                                            : ''
                                    }

                                </ul>
                                : ''
                        }

                    </div>
                    {(this.state.lookalikes !== null) ? (this.state.lookalikes.length) ?
                        <div className="container-leads-lookalikes-user">
                            <h3>Lookalikes</h3>
                            <div class="lead-lookalikes-list">
                                {(this.state.lookalikes.map((lookalike) => {
                                    return (
                                        <NavLink className="link-leads-item" key={lookalike.uuid} to={"/leads/" + lookalike.uuid}><Element data={lookalike} type="lookalike" score={lookalike.score} /></NavLink>
                                    )
                                }))}
                            </div>
                        </div> : '' : ''}

                </StickyBox>
            </div>

            <VerticalTimeline animate={false} layout={'1-column'} >


                <InfiniteScroll loader={<InfiniteTimelineLoader />} dataLength={this.state.events.length} next={this.loadMore} throttle={100} threshold={300} isLoading={this.state.isLoading} hasMore={this.state.hasMore}>
                    {
                        this.state.events.length > 0
                            ? this.state.events.map(event => (<VerticalTimelineElement key={event.id} className="vertical-timeline-element--work" iconStyle={{
                                background: (event.action === 'pageview')
                                    ? this.state.actionColors.pageview
                                    : (event.action === 'zoneview')
                                        ? this.state.actionColors.zoneview
                                        : (
                                            (event.action === 'click')
                                                ? this.state.actionColors.click
                                                : (
                                                    (event.action === 'identification')
                                                        ? this.state.actionColors.identification
                                                        :
                                                        (event.action === 'form')
                                                            ? this.state.actionColors.form
                                                            : (
                                                                (event.action === 'mailview')
                                                                    ? this.state.actionColors.mailview
                                                                    : ''))),
                                color: '#fff'
                            }} icon={<Icon icon={
                                event.action
                            } />}>
                                <Event data={event} actionColor={this.state.actionColors[event.action]}></Event>
                            </VerticalTimelineElement>))
                            : null
                    }
                </InfiniteScroll>

            </VerticalTimeline>

        </div>)
    }

}

export default Details;
