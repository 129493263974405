import React, { Component } from 'react';
import axios from 'axios';

import __ from '../../Helpers/Translate';

import { NavLink } from 'react-router-dom';
import { SortableContainer, SortableElement, SortableHandle, arrayMove } from 'react-sortable-hoc';

import TextValidator from '../../Elements/Forms/TextValidator';
import { ValidatorForm } from 'react-form-validator-core';
import MessagesHelpers from '../../Helpers/MessagesHelpers';

class Ruleset extends Component {

    constructor(props) {
        super(props);
        this.state = {
            uuid: this.props.match.params.ruleset,
            rules: [],
            createRuleError: false,
            createRuleErrorMessage: '',
            updateRulesetError: false,
            updateRulesetErrorMessage: '',
            name: '',
            description: '',
            ruleName: '',
            displayFormCreate: false,
            inputSearch: ''
        };
    }

    onSortEnd = ({
        oldIndex,
        newIndex
    }, event) => {
        this.setState({
            rules: arrayMove(this.state.rules, oldIndex, newIndex)
        });

        axios.post(`ruleset/${this.state.uuid}/rule/edit`, {
            uuid: this.state.rules[newIndex].uuid,
            priority: newIndex
        }).then(res => {
            this.getRulesetRules();
        }).catch(error => { });
    };

    componentDidMount() {
        this.setState({ uuid: this.props.match.params.ruleset });
        this.getRulesetRules();
        this.getRulesetData();
    }

    getRulesetData() {
        axios.get(`ruleset/${this.state.uuid}`).then(res => {
            this.setState(state => ({ name: res.data.name, description: res.data.description }));
        }).catch(error => { });
    }

    getRulesetRules() {
        axios.get(`ruleset/${this.state.uuid}/rule`).then(res => {
            this.setState(state => ({ rules: res.data }));
        }).catch(error => { });
    }

    deleteRuleset(uuid) {
        axios.post(`ruleset/delete`, { uuid: uuid }).then(res => {
            this.props.history.push(`/scoring`)
        }).catch(error => { });
    }

    updateRuleset(e) {
        e.preventDefault();

        if (this.state.name !== '') {
            axios.post(`ruleset/edit`, {
                uuid: this.state.uuid,
                name: this.state.name,
                description: this.state.description
            }).then(res => {
                this.getRulesetRules();
                this.setState({ updateRulesetError: false, updateRulesetErrorMessage: '' });
                MessagesHelpers.pushMessage('success', __('configuration.rules.update.messages.success'));
            }).catch(error => {
                this.setState({ updateRulesetError: true, updateRulesetErrorMessage: 'Error' });
                MessagesHelpers.pushMessage('error', __('configuration.rules.update.messages.error'));
            });
        } else {
            this.setState({ updateRulesetError: true, updateRulesetErrorMessage: 'Name is required' });
        }
    }

    createRule(e) {
        e.preventDefault();

        if (this.state.ruleName !== '') {
            axios.post(`ruleset/${this.state.uuid}/rule/edit`, {
                name: this.state.ruleName,
                description: this.state.description
            }).then(res => {
                this.getRulesetRules();
                this.setState({ createRuleError: false, createRuleErrorMessage: '', displayFormCreate: false, ruleName: '', description: '' });
                MessagesHelpers.pushMessage('success', __('configuration.rules.create.messages.success'));
            }).catch(error => {
                this.setState({ createRuleError: true, createRuleErrorMessage: 'Error' });
                MessagesHelpers.pushMessage('error', __('configuration.rules.create.messages.error'));
            });
        } else {
            this.setState({ createRuleError: true, createRuleErrorMessage: 'Name is required' });
        }
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    shouldCancelStart(e) {
        // Cancel sorting if the event target is an anchor tag (`a`)
        if (e.target.tagName.toLowerCase() === 'a') {
            return true; // Return true to cancel sorting
        }
    }

    displayFormCreate(e) {
        e.preventDefault();
        this.setState({ displayFormCreate: true });
    }

    hideFormCreate(e) {
        e.preventDefault();
        this.setState({ displayFormCreate: false });
    }

    onChangeHandlerSearch(e) {
        this.setState({ inputSearch: e.target.value })
    }

    render() {
        const SortableDargEl = SortableHandle(() => <div className="rules-list-grab">
            <div className="rules-list-grab__el"></div>
        </div>);

        const SortableItem = SortableElement(({ name, uuid, order }) => <li className="rules-list__el" ruleUuid={uuid}>
            <p className="rules-list__name">{name}</p>
            <NavLink className="rules-list__button" key={uuid} to={`/scoring/${this.state.uuid}/${uuid}`}>Edit</NavLink>
            <SortableDargEl />
        </li>);

        const toto = this.state.rules.filter(rule => rule.name.toLowerCase().includes(this.state.inputSearch.toLowerCase()) || this.state.inputSearch === '')

        const SortableList = SortableContainer(({ items }) => {
            return (<ol className="rules-list">
                {
                    items.map(({
                        name,
                        uuid,
                        order
                    }, index) => (<SortableItem key={`item-${index}`} index={index} order={order} uuid={uuid} name={name} />))
                }
            </ol>);
        });

        return (<div className="rules-container">

            <ValidatorForm className="form-config" ref="form" debounceTime={500} onSubmit={this.updateRuleset.bind(this)}>

                <legend className="form-config__title">Update name and description of this ruleset</legend>
                <div className="form-config__container-flex">
                    <TextValidator onChange={this.handleChange.bind(this)} type="text" name="name" placeholder="Name" value={this.state.name} labelCopy="Name" classNameGlobal={["form-config__el", "form-config__label", "form-config__input"]} validators={['required']} errorMessages={[__('error.message.thisfieldisrequired')]} />

                    <div className="form-config__el">
                        <label className="form-config__label">Description:</label>
                        <input className="form-config__input" type="text" name="description" placeholder="Description" value={this.state.description} onChange={this.handleChange.bind(this)} />
                    </div>
                </div>
                <div className="form-config__container-button">
                    <button className="form-config__button" type="submit">Update</button>

                    <button className="form-config__button form-config__button--delete" onClick={() => this.deleteRuleset(this.state.uuid)}>Delete</button>
                </div>

            </ValidatorForm>

            <div className="rules-list-container">

                <div className="rules-list-container__title">
                    <h2>Rules list</h2>
                    <input placeholder="Search" className='input-filters' value={this.state.inputSearch} type="text" onChange={this.onChangeHandlerSearch.bind(this)} />
                </div>

                <SortableList useDragHandle={true} lockAxis="y" shouldCancelStart={this.shouldCancelStart} items={toto} onSortEnd={this.onSortEnd} />
                <div className="form-config__container-button">
                    <button className="form-config__button" onClick={this.displayFormCreate.bind(this)}>Creat new rule</button>
                </div>
            </div>

            {
                (this.state.displayFormCreate)
                    ? <ValidatorForm className="form-config" ref="form" debounceTime={500} onSubmit={this.createRule.bind(this)}>

                        <legend className="form-config__title">Create new rule</legend>

                        <div className="form-config__container-flex">
                            <TextValidator onChange={this.handleChange.bind(this)} type="text" name="ruleName" placeholder="Name" value={this.state.ruleName} labelCopy="Name" classNameGlobal={["form-config__el", "form-config__label", "form-config__input"]} validators={['required']} errorMessages={[__('error.message.thisfieldisrequired')]} />
                        </div>
                        <div className="form-config__container-button">
                            <button className="form-config__button" type="submit">Save</button>

                            <button className="form-config__button form-config__button--cancel" onClick={this.hideFormCreate.bind(this)}>Cancel</button>
                        </div>

                    </ValidatorForm>
                    : ''
            }
        </div>)
    }

}

export default Ruleset;