import React, {Component} from 'react';
import __ from '../../../../Helpers/Translate';
import Conf from '../../../../Conf';

class ConfigurationForm extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    saveConfiguration(e) {
        e.preventDefault();
        this.props.onSave(this.state);
    }

    componentDidMount() {
        this.props.configuration.forEach(item => {
            //let currentState = this.state.data;
            //currentState[item.name] = item.value;
            this.setState({
                [item.name]: item.value
            });

            let token = '';
            let split = [];

            if (window.location.href.includes('?oauth_token=')) {
                split = window.location.href.split('?oauth_token=');
                token = split[1];

                if (token.includes('&update=')) {

                    split = token.split('&update=');
                    token = split[0];

                    this.setState({authenticate: token});

                    setTimeout(() => {
                        this.props.onSave(this.state);
                    }, 500);
                }
            }
        });
    }

    handleFieldChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    oauthAction(url) {
        this.props.onSave(this.state);
        setTimeout(() => {
            let currUrl = window.location.href;
            window.location.href = (Conf.oauthBaseDomain + url + '?redirect=' + encodeURI(currUrl)) + '&account=' + this.props.account.uuid;
        }, 1000);
    }

    render() {
        return (<form className="form-plugin" onSubmit={this.saveConfiguration.bind(this)}>

            {
                this.props.configuration.map(item => {

                    let itemValue = item.value;
                    let currentStateData = this.state;
                    if (currentStateData[item.name] !== undefined) {
                        itemValue = currentStateData[item.name];
                    }

                    return (<div className="form-plugin__el">
                        {
                            (item.type === 'text')
                                ? <span>
                                        <label className="form-plugin__label">{item.label}</label>
                                        <input className="form-plugin__input" onChange={this.handleFieldChange.bind(this)} name={item.name} type="text" placeholder={item.placeholder} value={itemValue} required={item.required}/>
                                    </span>
                                : ''
                        }
                        {
                            (item.type === 'password')
                                ? <span>
                                        <label className="form-plugin__label">{item.label}</label>
                                        <input className="form-plugin__input" onChange={this.handleFieldChange.bind(this)} name={item.name} type="password" placeholder={item.placeholder} value={itemValue} required={item.required}/>
                                    </span>
                                : ''
                        }
                        {
                            (item.type === 'info')
                                ? <span>
                                        <label className="form-plugin__label">{item.label}</label>
                                        {itemValue}
                                    </span>
                                : ''
                        }
                        {
                            (item.type === 'oauth')
                                ? <span>
                                        <button onClick={this.oauthAction.bind(this, item.url)}>{item.label}</button>
                                        <input className="form-plugin__input" onChange={this.handleFieldChange.bind(this)} name={item.name} type="hidden" value={itemValue} required={item.required}/>
                                    </span>
                                : ''
                        }
                    </div>)
                })
            }

            <button className="form-plugin__button" type="submit">{__('configuration.plugins.conf.save')}</button>

        </form>);
    }
}

export default ConfigurationForm;
