import React, { Component } from 'react';

import __ from '../Helpers/Translate';
import cachios from 'cachios';

import Status from './Status';

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      apiBuild: '...'
    }
  }

  componentDidMount() {
    cachios.get('info', { ttl: 300 }).then(res => {
      this.setState({ apiVersion: res.data.version, apiBuild: res.data.build });
    });
  }

  render() {
    return (<div className="footer">

      <Status /> <br />

      {__('footer.content')}
      &nbsp;-&nbsp;<i>{this.state.apiBuild}</i>
    </div>);
  }
}

export default Footer;
