import __ from './Helpers/Translate';

let Conf = {
	// API
  apiBaseDomain: "https://ttbk.riseandshine.rocks/api/",
	oauthBaseDomain: "https://ttbk.riseandshine.rocks/",
  socketBaseDomain: "https://ttbk.riseandshine.rocks",
  socketPort: '6001',

	// SOCIAL LOGIN
	googleLogin: {
		clientId: "954720723486-e1f5lgbtadfarhhv9r1j97bn3gale1l5.apps.googleusercontent.com"
	},

	// BREADCRUMBS
  breadcrumbs: {
		// HOME
    '/': __('breadcrumbs.home'),

		// DASHBOARD
    '/dashboard': __('breadcrumbs.dashboard'),

		// LEADS
    '/leads': __('breadcrumbs.leads'),
    '/leads/:uuid': __('breadcrumbs.leads.item'),

		// SEGMENTS
    '/segments': __('breadcrumbs.segments'),
    '/segments/:uuid': __('breadcrumbs.segments.edit'),

		// FLOWS
    '/actions': __('breadcrumbs.actions'),
    '/actions/:uuid': __('breadcrumbs.actions.item'),

		// RULES
    '/scoring': __('breadcrumbs.scoring'),
    '/scoring/:uuid': __('breadcrumbs.scoring.item'),
    '/scoring/:uuid/:uuid': __('breadcrumbs.scoring.item.rule.item'),

		// USER
    '/user': __('breadcrumbs.user'),
    '/user/organization': __('breadcrumbs.user.organization'),
    '/user/account': __('breadcrumbs.user.account'),
    '/user/team': __('breadcrumbs.user.team'),
    '/user/team/:uuid': __('breadcrumbs.user.teamitem'),

		// CATEGORIES
    '/tags': __('breadcrumbs.tags'),

		// CONFIG
    '/configuration': __('breadcrumbs.configuration'),
    '/configuration/domains': __('breadcrumbs.configuration.domains'),
    '/configuration/trackers': __('breadcrumbs.configuration.trackers'),
    '/configuration/trackers/:type': '',
  }
}

export default Conf;
