import React, { Component } from 'react';

import __ from '../Helpers/Translate';
import pageTitle from '../Helpers/PageHelpers';
class NoMatch extends Component {
	componentDidMount(){

				pageTitle(__('error.pageTitle'));
	}
  render() {
    return (
      <div className="dashboard">
				{__('error.errorMessage')}
      </div>
    );
  }
}

export default NoMatch;
