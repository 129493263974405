import React, { Component } from 'react'
import axios from 'axios'

//PICTO SVG
import { FaSyncAlt, FaCalendarAlt, FaSearch } from 'react-icons/fa'
// DATEPICKER
//import 'react-date-range/dist/styles.css' // main style file
//import 'react-date-range/dist/theme/default.css' // theme css file
import { DateRangePicker } from 'react-date-range'

import Select from 'react-select'

import __ from '../../../Helpers/Translate'

import countries from 'i18n-iso-countries'
import StickyBox from "react-sticky-box"

class Filters extends Component {
    constructor(props) {
        super(props)
        this.state = {
            countryItems: [],
            segmentItems: [],
            displayDateRange: false,
            // displayFilters: false
        }
    }

    componentDidMount() {
        let lang = 'en'
        if (window.appLang !== '' && window.appLang !== undefined) {
            lang = window.appLang
        }
        countries.registerLocale(require("i18n-iso-countries/langs/" + lang + ".json"))

        axios.get(`datasets/country`, { ttl: 300 }).then(res => {
            let finalArray = []
            finalArray.push({ value: '', label: __('leads.global.lists.any') })
            res.data.map(function (item) {
                finalArray.push({
                    value: item.key,
                    label: countries.getName(item.value, lang)
                })
                return true
            })
            this.setState({ countryItems: finalArray })
        })

        axios.get(`segment`).then(res => {
            let finalArray = []
            finalArray.push({ value: '', label: __('leads.global.lists.any') })
            res.data.map(function (item) {
                finalArray.push({ value: item.uuid, label: item.name })
                return true
            })
            this.setState({ segmentItems: finalArray })
        })
    }

    toggleDateRange() {
        this.setState({
            displayDateRange: !this.state.displayDateRange
        })
    }

    updateTypeFilter(e) {
        this.props.updateFilter('filterType', e.value)
    }

    updateCountryFilter(e) {
        this.props.updateFilter('filterCountry', e.value)
    }

    updateSegmentFilter(e) {
        this.props.updateFilter('filterSegment', e.value)
    }

    closeFiltersAndLoadList(e) {
        e.preventDefault()

        this.props.closeFilters()
        this.props.loadList()
    }

    render() {
        const selectionRange = {
            startDate: this.props.filterDateFrom,
            endDate: this.props.filterDateTo,
            key: 'selection'
        }

        const typeFilterOptions = [
            {
                value: '',
                label: __('leads.global.lists.any')
            }, {
                value: 'anonymous',
                label: __('leads.filters.type.anonymous')
            }, {
                value: 'known',
                label: __('leads.filters.type.known')
            }
        ]

        return (<StickyBox className="container-list-filters" offsetTop={0}>

            <div className={(
                this.props.displayFilters)
                ? 'mob-container-list-filters -display'
                : 'mob-container-list-filters'}>
                <ul className="list-filters">
                    <li className="list-filters__el list-filters__el--title">
                        Filters
                        <button className="close-action-filters" onClick={this.props.closeFilters.bind(this)}></button>
                    </li>
                    <li className="list-filters__el list-filters__el--search">
                        <FaSearch className="svg-input-filters" />
                        <input autoComplete="off" className="input-filters" data-typetext="true" placeholder={__('leads.filters.freesearch.label')} onChange={this.props.updateFilters.bind(this)} type="text" name="filterSearch" value={this.props.filterSearch} />
                    </li>

                    {/*

											<li className="list-filters__el">
													<Select className="select-filters react-select-container" placeholder={__('leads.filters.country.label')} classNamePrefix="react-select" onChange={this.updateCountryFilter.bind(this)} value={this.props.filterCountry} options={this.state.countryItems} />
											</li>

											*/}

                    <li className="list-filters__el">
                        <Select className="select-filters react-select-container" placeholder={__('leads.filters.segment.label')} classNamePrefix="react-select" onChange={this.updateSegmentFilter.bind(this)} value={this.props.filterSegment} options={this.state.segmentItems} />
                    </li>

                    <li className="list-filters__el">
                        <Select className="select-filters react-select-container" placeholder={__('leads.filters.type.label')} classNamePrefix="react-select" onChange={this.updateTypeFilter.bind(this)} value={this.props.filterType} options={typeFilterOptions} />
                    </li>

                    <li className="list-filters__el list-filters__el--button list-filters__el--date">
                        <button className={(
                            this.state.displayDateRange)
                            ? 'button-filters -active'
                            : 'button-filters'} onClick={this.toggleDateRange.bind(this)}>
                            <FaCalendarAlt className="button-filters__picto" />{__('leads.filters.daterange.label')}
                        </button>
                    </li>
                    <li className="list-filters__el list-filters__el--button list-filters__el--list-grid">
                        <button className={(
                            this.props.listType === 'list')
                            ? 'button-change-view-filters -active'
                            : 'button-change-view-filters'} onClick={this.props.userChangeView.bind(this, "list")}>
                            <div className="button-change-view-filters__el"></div>
                            <div className="button-change-view-filters__el"></div>
                        </button>
                        <button className={(
                            this.props.listType === 'grid')
                            ? 'button-change-view-filters -active'
                            : 'button-change-view-filters'} onClick={this.props.userChangeView.bind(this, "grid")}>
                            <div className="button-change-view-filters__el button-change-view-filters__el--for-4"></div>
                            <div className="button-change-view-filters__el button-change-view-filters__el--for-4"></div>
                        </button>
                    </li>
                    <li className="list-filters__el list-filters__el--button">
                        <button className="button-refresh-filters" onClick={this.closeFiltersAndLoadList.bind(this)}>
                            <FaSyncAlt className="icon" />{__('leads.filters.refresh')}
                        </button>
                    </li>

                </ul>

                <div className="container-datepicker-filters">
                    {
                        (this.state.displayDateRange)
                            ? <DateRangePicker className="datepicker-filters" onChange={this.props.updateDateFilter} ranges={[selectionRange]} maxDate={new Date()} direction="horizontal" />
                            : ''
                    }
                </div>

            </div>

        </StickyBox>)
    }
}

export default Filters
