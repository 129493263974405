import React, { Component } from 'react';

import { CheckPermissions } from '../../Helpers/Permission';

import { NavLink } from 'react-router-dom'

import {
  FaTachometerAlt,
  FaUsers,
  FaBolt,
  FaTags,
  FaUserTag,
  FaRulerVertical
} from 'react-icons/fa';

import __ from '../../Helpers/Translate';

class Default extends Component {

  render() {
    return (<ul className="nav-aside-right nav-aside-right__el--mainenu">
      {
        CheckPermissions('access_dashboard') && <li className="nav-aside-right__el">
          <NavLink className="link-aside-right" to="/dashboard">
            <FaTachometerAlt />
            <span className="link-aside-right__copy">{__('menu.dashboard')}</span>
          </NavLink>
        </li>
      }
      {
        CheckPermissions('access_leads') && <li className="nav-aside-right__el">
          <NavLink className="link-aside-right" to="/leads">
            <FaUsers />
            <span className="link-aside-right__copy">{__('menu.leads')}</span>
          </NavLink>
        </li>
      }
      {
        CheckPermissions('access_segments') && <li className="nav-aside-right__el">
          <NavLink className="link-aside-right" to="/segments">
            <FaUserTag />
            <span className="link-aside-right__copy">{__('menu.segmentation')}</span>
          </NavLink>
        </li>
      }
      {
        CheckPermissions('access_dashboard') && false && <li className="nav-aside-right__el">
          <NavLink className="link-aside-right" to="/dashboard">
            <FaTachometerAlt />
            <span className="link-aside-right__copy">{__('menu.dashboard')}</span>
          </NavLink>
        </li>
      }
      {
        CheckPermissions('configure_tags') && <li className="nav-aside-right__el">
          <NavLink className="link-aside-right" to="/tags">
            <FaTags />
            <span className="link-aside-right__copy">{__('menu.tags')}</span>
          </NavLink>
        </li>
      }
      {
        CheckPermissions('configure_rules') && <li className="nav-aside-right__el">
          <NavLink className="link-aside-right" to="/scoring">
            <FaRulerVertical />
            <span className="link-aside-right__copy">{__('menu.scoring')}</span>
          </NavLink>
        </li>
      }
      {
        CheckPermissions('access_actions') && <li className="nav-aside-right__el">
          <NavLink className="link-aside-right" to="/actions">
            <FaBolt />
            <span className="link-aside-right__copy">{__('menu.actions')}</span>
          </NavLink>
        </li>
      }
    </ul>);
  }
}

export default Default;
