import React, {Component} from 'react';
import axios from 'axios';

import Moment from 'react-moment';
import 'moment-timezone';

import {NavLink} from 'react-router-dom';

class List extends Component {

    constructor(props) {
        super(props);
        this.state = {
            users: false
        };
    }

    componentDidMount() {
        this.getUsers();
    }

    getUsers() {
        axios.get(`account/user`).then(res => {
            this.setState(state => ({users: res.data}))
        }).catch(error => {})
    }

    render() {
        return (<div className="team-container">

            <ul className="team-list">
                {
                    (this.state.users !== false)
                        ? this.state.users.map(function(user) {
                            return (<li className="team-el">
                                <div className="team-el__container">
                                    <p className="team-el__name">
                                        {user.name}
                                    </p>
                                    <p className="team-el__email">
                                        {user.email}
                                    </p>
                                    <p className="team-el__since">
                                        Since
                                        <Moment format="D MMM YYYY" locale="en">{user.created_at.date}</Moment>
                                    </p>
                                </div>

                                <NavLink className="team-el__button" key={user.uuid} to={"/user/team/" + user.uuid}>Edit</NavLink>
                            </li>)
                        })
                        : ''
                }
            </ul>

            <NavLink className="button-team-add" key='new' to='/user/team/new'>Add user</NavLink>

        </div>);
    };

}

export default List;
