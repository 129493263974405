import React, { Component } from 'react';

//import __ from '../Helpers/Translate';

class Status extends Component {
  constructor(props) {
    super(props);
    this.state = {

      status: {
        scoring: {
          valid: true,
          calculation: false
        },
        segments: {
          calculation: false
        },
        population: {
          indexation: false
        }
      }
    };
  }

  componentDidMount() {
    window.echo.channel('status.alert.' + window.userUuid).listen('Status', (event) => {
      this.statusChange(event);
    });
  }

  statusChange(event) {
    let statusObj = this.state.status;

    if (event.name === "scoring.invalid") {
      statusObj['scoring']['valid'] = !event.value
    }

    if (event.name === "calculate.segments") {
      statusObj['segments']['calculation'] = event.value
    }

    if (event.name === "calculate.scoring") {
      statusObj['scoring']['calculation'] = event.value
    }

    if (event.name === "population.indexation") {
      statusObj['population']['indexation'] = event.value
    }

    this.setState({
      status: statusObj
    });
  }

  render() {
    return (
      <div className="status">
        <div className="status-line">
          <span>
            Scoring calculation:&nbsp;
              </span>
          <span className="value">
            {
              (this.state.status.scoring.calculation)
                ? 'Running...'
                : 'Up to date'
            }
          </span>
        </div>

        <div className="status-line">
          <span>
            Segments calculation:&nbsp;
              </span>
          <span className="value">
            {
              (this.state.status.segments.calculation)
                ? 'Running...'
                : 'Up to date'
            }
          </span>
        </div>

        <div className="status-line">
          <span>
            Population index:&nbsp;
              </span>
          <span className="value">
            {
              (this.state.status.population.indexation)
                ? 'Running...'
                : 'Up to date'
            }
          </span>
        </div>
      </div>);
  }
}

export default Status;
