import React, {Component} from 'react';
import {FaEye} from 'react-icons/fa';
import {FaMousePointer} from 'react-icons/fa';
import {FaKeyboard} from 'react-icons/fa';
import {FaEnvelope} from 'react-icons/fa';
import {FaMobileAlt} from 'react-icons/fa';
import {FaTabletAlt} from 'react-icons/fa';
import {FaDesktop} from 'react-icons/fa';
import {FaApple} from 'react-icons/fa';
import {FaAndroid} from 'react-icons/fa';
import {FaWindows} from 'react-icons/fa';
import {FaChrome} from 'react-icons/fa';
import {FaFirefox} from 'react-icons/fa';
import {FaSafari} from 'react-icons/fa';
import {FaOpera} from 'react-icons/fa';
import {FaWindowMaximize} from 'react-icons/fa';
import {FaSpinner} from 'react-icons/fa';
import {FaStar} from 'react-icons/fa';

class Icon extends Component {
  getIcon() {
    if (this.props.icon === 'identification') {
      return (<FaStar/>);
    }
    if (this.props.icon === 'pageview') {
      return (<FaEye/>);
    }
    if (this.props.icon === 'zoneview') {
      return (<FaEye/>);
    }
    if (this.props.icon === 'click') {
      return (<FaMousePointer/>);
    }
    if (this.props.icon === 'form') {
      return (<FaKeyboard/>);
    }
    if (this.props.icon === 'mailview') {
      return (<FaEnvelope/>);
    }
    if (this.props.icon === 'mobile') {
      return (<FaMobileAlt/>);
    }
    if (this.props.icon === 'tablet') {
      return (<FaTabletAlt/>);
    }
    if (this.props.icon === 'desktop') {
      return (<FaDesktop/>);
    }
    if (this.props.icon === 'android') {
      return (<FaAndroid/>);
    }
    if (this.props.icon === 'macos') {
      return (<FaApple/>);
    }
    if (this.props.icon === 'ios') {
      return (<FaApple/>);
    }
    if (this.props.icon === 'windows') {
      return (<FaWindows/>);
    }
    if (this.props.icon === 'chrome') {
      return (<FaChrome/>);
    }
    if (this.props.icon === 'firefox') {
      return (<FaFirefox/>);
    }
    if (this.props.icon === 'safari') {
      return (<FaSafari/>);
    }
    if (this.props.icon === 'opera') {
      return (<FaOpera/>);
    }
    if (this.props.icon === 'browser') {
      return (<FaWindowMaximize/>);
    }
    if (this.props.icon === 'spinner') {
      return (<FaSpinner/>);
    }
    return (<span className="no-icon"></span>)
  }

  render() {
    return (this.getIcon());
  }
}

export default Icon;
