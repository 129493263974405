import React, {Component} from 'react';

class LeadName extends Component {
  render() {
    console.log(this.props.lead);
    return (<span>
      {

				(this.props.lead !== undefined && this.props.lead.identifier!==undefined)

				?

        this.props.lead.identifier

				:

				'Known lead'

			}
    </span>);
  }
}

export default LeadName;
