import React, {Component} from 'react';
import {FaUsers} from 'react-icons/fa';

class Default extends Component {
    render() {
        return (<div className="default-container default" style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                textAlign: 'center',
                color: '#bbb',
                fontSize: 280
            }}>

            <FaUsers style={{
                    margin: '0 auto'
                }}/>
        </div>);
    }
}

export default Default;
