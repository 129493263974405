import React, {Component} from 'react';


class Scores extends Component {
  render() {

    return (<ul className="leads-scores-user">

			{(this.props.lead!==undefined)?(this.props.lead!==null)?
					(this.props.lead.scores!==undefined)?
						(this.props.lead.scores.length>0)?
						this.props.lead.scores.map(function(score){
							return (

									<li key={score.name} className="leads-scores-user__el">
										<span className="leads-scores-user__color" style={{background:score.color}}></span>
										<h5 className="leads-scores-user__name">{score.name}</h5>
										<p className="leads-scores-user__score">{score.score} {(score.score>1)?'pts':'pt'}</p>
									</li>

							)
						})
						:''
					:''
				:''
			:''}

    </ul>);
  }
}

export default Scores;

// import React, {Component} from 'react';


// class Scores extends Component {
//   render() {

//     return (<ul className={(this.props.lead!==undefined)?(this.props.lead!==null)?(this.props.lead.scores!==undefined)?(this.props.lead.scores.length>0)? 'leads-scores-user' : 'leads-scores-user':'leads-scores-user':'leads-scores-user':'leads-scores-user'}>

// 			{(this.props.lead!==undefined)?(this.props.lead!==null)?
// 					(this.props.lead.scores!==undefined)?
// 						(this.props.lead.scores.length>0)?
// 						this.props.lead.scores.map(function(score){
// 							return (

// 									<li className="leads-scores-user__el">
// 										<span className="leads-scores-user__color" style={{background:score.color}}></span>
// 										<h5 className="leads-scores-user__name">{score.name}</h5>
// 										<p className="leads-scores-user__score">{score.score} {(score.score>1)?'pts':'pt'}</p>
// 									</li>

// 							)
// 						})
// 						:''
// 					:''
// 				:''
// 			:''}

//     </ul>);
//   }
// }

// export default Scores;
