import React, { Component } from 'react';

import { CheckPermissions } from '../../Helpers/Permission';

import {
	NavLink,
} from 'react-router-dom'

import {
	FaArrowLeft,
	FaGlobeAmericas,
	FaLink,
	FaPlug,
	FaUsers
} from 'react-icons/fa';

import __ from '../../Helpers/Translate';



class Configuration extends Component {

	render() {
		return (

			<ul className="nav-aside-right nav-aside-right__el--mainenu">
				<li className="nav-aside-right__el">
					<button className="link-aside-right -back" onClick={this.props.backAction.bind(this)}>
						<FaArrowLeft />
						<span className="link-aside-right__copy">{__('menu.user.back')}</span>
					</button>
				</li>

				{
					CheckPermissions('configure_domains') && <li className="nav-aside-right__el">
						<NavLink className="link-aside-right" to="/configuration/domains">
							<FaGlobeAmericas />
							<span className="link-aside-right__copy">{__('configuration.subnav.domains')}</span>
						</NavLink>
					</li>
				}
				{
					true && <li className="nav-aside-right__el">
						<NavLink className="link-aside-right" to="/configuration/identification">
							<FaUsers />
							<span className="link-aside-right__copy">{__('configuration.subnav.identification')}</span>
						</NavLink>
					</li>
				}
				{
					true && <li className="nav-aside-right__el">
						<NavLink className="link-aside-right" to="/configuration/trackers">
							<FaLink />
							<span className="link-aside-right__copy">{__('configuration.subnav.trackers')}</span>
						</NavLink>
					</li>
				}			{
					true && <li className="nav-aside-right__el">
						<NavLink className="link-aside-right" to="/configuration/plugins">
							<FaPlug />
							<span className="link-aside-right__copy">{__('configuration.subnav.plugins')}</span>
						</NavLink>
					</li>
				}
			</ul>







		);
	}
}

export default Configuration;
