import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import List from './Leads/List';
import Details from './Leads/Details';

import __ from '../Helpers/Translate';
import pageTitle from '../Helpers/PageHelpers';

class Leads extends Component {
  componentDidMount() {
    pageTitle(__('leads.pageTitle'));
  }

  render() {
    return (
      // POC In-Page LEAD DETAILS

      // <div className="leads-global__container merged-panels__container">


      //   <List />

      //   <Switch>
      //     {/* <Route exact={true} path="/leads" component={List} /> */}
      //     <Route exact={true} path="/leads/:uuid" component={Details} />
      //   </Switch>
      // </div>

      <Switch>
        <Route exact={true} path="/leads" component={List} />
        <Route exact={true} path="/leads/:uuid" component={Details} />
      </Switch>
    );
  }
}

export default Leads;
