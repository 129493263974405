export function CheckPermissions(permission) {
    let status = false

    if (window.userPermissions) {
        window.userPermissions.forEach((userPermission) => {
            if (userPermission.name === permission) {
                if (userPermission.status === 1) {
                    status = true
                }
            }
        })
    }

    return status;
}
